import customRequestTypes from '../../../core/constants/custom-request-types';

export const PAGE_LOAD_REQUESTS = [
  {
    requestTypeName: customRequestTypes.WEBMAIL_FETCH_SITE_META_API
  },
  {
    requestTypeName: customRequestTypes.WEBMAIL_FETCH_TRANSLATIONS
  },
  {
    requestTypeName: customRequestTypes.LOAD_TRANSLATIONS
  },
  {
    requestTypeName: customRequestTypes.LOGOUT_FROM_WM
  }
];

export const WM_REDUX_FORM = {
  CREATE_AUTO_CONFIGURE_WEB_MAIL: 'CREATE_AUTO_CONFIGURE_WEB_MAIL',
  CHANGE_EMAIL_PASSWORD: 'CHANGE_EMAIL_PASSWORD',
  CHANGE_EMAIL_AUTORESPONDER_DIALOG_WEB_MAIL: 'CHANGE_EMAIL_AUTORESPONDER_DIALOG_WEB_MAIL',
  CREATE_AUTO_RESPONDER_WEB_MAIL: 'CREATE_AUTO_RESPONDER_WEB_MAIL',
  CREATE_EMAIL_FILTER_ITEM: 'CREATE_EMAIL_FILTER_ITEM',
  CHANGE_EMAIL_FILTERS_DIALOG: 'CHANGE_EMAIL_FILTERS_DIALOG',
  CREATE_FORWARDERS_WEB_MAIL: 'CREATE_FORWARDERS_WEB_MAIL',
  UPDATE_FORWARDERS_WEB_MAIL: 'UPDATE_FORWARDERS_WEB_MAIL'
};
