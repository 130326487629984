import * as React from 'react';
import { injectIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import customRequestTypes from '../../../../core/constants/custom-request-types';
import CreateBox from '../../../../web/components/create-box';
import { WM_REDUX_FORM } from '../../../core/constants/common';

type Props = {
  children: any;
  intl: any;
  renderBeforeBoxChildren: any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.EMAIL;
const formName = WM_REDUX_FORM.CREATE_AUTO_CONFIGURE_WEB_MAIL;

const CreateContainer = ({ children, intl, renderBeforeBoxChildren }: Props) => (
  <CreateBox
    hideTutorials
    title={intl.formatMessage({ id: 'translate.wm.mail.config.mail.client.config.title' })}
    resourceName={resourceName}
    formName={formName}
    renderBeforeBoxChildren={renderBeforeBoxChildren}
    resources={[
      { resourceName: API_RESOURCE.EMAIL.resourceName, methods: ['GET'] },
      { resourceName: API_RESOURCE.DOMAIN_ALL.resourceName, methods: ['GET'] },
      { resourceName: API_RESOURCE.EMAIL.resourceName, methods: ['PUT'] },
      { requestTypeName: customRequestTypes.EMAIL_AUTOCONFIGURE_DATA, methods: ['PUT'] }
    ]}
  >
    {children}
  </CreateBox>
);

export default injectIntl(CreateContainer);
