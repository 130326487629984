import * as React from 'react';
import { injectIntl } from 'react-intl';
import {
  Box,
  cn,
  Colors,
  Container,
  Context,
  Flex,
  GetDeviceInformationType,
  Icon,
  Section,
  Text,
  textToHTML
} from '@siteground/styleguide';
import { getPageInfo, ToolId, WmToolId } from '../../../core/constants/route-info';
import { HideOnMobile } from '../device';
import { FirstLevelTitle } from '../titles';
import './page-header.scss';

type Props = {
  id: ToolId | WmToolId;
  instructions?: string | React.ReactNode;
  render?: Function;
  renderNoticeText?: Function;
  device?: GetDeviceInformationType;
};

type DispatchProps = {
  intl: Intl;
};

type State = {
  showReadMore: boolean;
  isTextHigherThenMaxLineHeight: boolean;
};

const MAX_TEXT_HEIGHT = 44;

class PageHeader extends React.Component<Props & DispatchProps, State> {
  readonly state = {
    showReadMore: true,
    isTextHigherThenMaxLineHeight: false
  };

  textRef = React.createRef() as any;

  componentDidMount() {
    if (!this.textRef.current) {
      return;
    }

    const isTextHigherThenMaxLineHeight = this.textRef.current.getBoundingClientRect().height > MAX_TEXT_HEIGHT;
    this.setState({
      isTextHigherThenMaxLineHeight
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.textRef.current) {
      return;
    }

    if (prevProps.device.width !== this.props.device.width) {
      const isTextHigherThenMaxLineHeight = this.textRef.current.getBoundingClientRect().height > MAX_TEXT_HEIGHT;
      this.setState({
        isTextHigherThenMaxLineHeight
      });
    }
  }

  render() {
    const { showReadMore, isTextHigherThenMaxLineHeight } = this.state;
    const {
      device,
      id,
      instructions,
      render,
      intl,
      renderNoticeText = (text: string) => textToHTML(text)
    } = this.props;
    const shouldTruncateText = isTextHigherThenMaxLineHeight && showReadMore && device.isPhone;
    const textClasses = cn('page-header__text', shouldTruncateText && 'page-header__text--overflow');

    const { icon, iconColor, multicolor, title } = getPageInfo(id);

    return (
      <Section className="page-header">
        <FirstLevelTitle>{title && intl.formatMessage({ id: title })}</FirstLevelTitle>

        {render && render()}

        {!render && (
          <Container className="page-header__content" tabIndex={0} role="figure">
            <Flex>
              <HideOnMobile>
                <Box sm="2" flex align="center" justify="center">
                  <Icon name={icon} color={iconColor} multicolor={multicolor} size="64" />
                </Box>
              </HideOnMobile>

              <Box sm="10">
                <Text>
                  <span
                    ref={this.textRef}
                    className={textClasses}
                    style={shouldTruncateText ? { maxHeight: `${MAX_TEXT_HEIGHT}px` } : undefined}
                  >
                    {typeof instructions === 'string' ? renderNoticeText(instructions) : instructions}
                  </span>
                </Text>

                {shouldTruncateText && (
                  <Text
                    className="page-header__toggle-button"
                    color="primary"
                    onClick={() => this.setState({ showReadMore: false })}
                    style={{ cursor: 'pointer' }}
                  >
                    {intl.formatMessage({ id: 'translate.generic.read.more' })}
                  </Text>
                )}

                {!showReadMore && (
                  <Text
                    className="page-header__toggle-button"
                    color="primary"
                    onClick={() => this.setState({ showReadMore: true })}
                    style={{ cursor: 'pointer' }}
                  >
                    {intl.formatMessage({ id: 'translate.generic.read.less' })}
                  </Text>
                )}
              </Box>
            </Flex>
          </Container>
        )}
      </Section>
    );
  }
}

export default injectIntl((props: Props & DispatchProps) => (
  <Context.Consumer>{({ device }) => <PageHeader device={device} {...props} />}</Context.Consumer>
)) as (props: Props) => any;
