import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, IconButton, Navigation } from '@siteground/styleguide';
import { getNavigationInfo } from '../../../../core/constants/route-info';
import { MenuItemST } from '../../../../core/definitions/menu-items';
import { rcmailItems } from '../../../core/constants/menu-items';
import NavigationItemCompact from './navigation-item-compact';

type Props = {
  onLogOut: Function;
  menuItems: MenuItemST[];
  onNavigationClick: Function;
  activePage: string;
  command: string;
  rcmailNavigationClick: Function;
  intl: Intl;
};

const NavigationListCompact: React.FunctionComponent<Props> = ({
  onLogOut,
  menuItems,
  onNavigationClick,
  activePage,
  command,
  rcmailNavigationClick,
  intl
}) => (
  <Navigation>
    <Grid padding={['x-small', 'none', 'none']} gap="none" align="center" justify="center">
      <IconButton
        size="large"
        icon="edit-file"
        onClick={() => rcmailNavigationClick({ command: 'compose', task: 'mail' })}
        tooltip={intl.formatMessage({ id: 'translate.wm.page.compose.button' })}
      />
    </Grid>
    <Grid padding={['x-small', 'none']} gap="none">
      {rcmailItems.map((item) => {
        return (
          <NavigationItemCompact
            key={item.address}
            icon={item.icon}
            tooltip={intl.formatMessage({ id: item.title })}
            onClick={() => rcmailNavigationClick({ task: item.address })}
            isActive={activePage === item.address && command !== 'compose'}
          />
        );
      })}
      <div className="wp-navigation-separator" />

      {menuItems.map((tool: MenuItemST) => {
        const toolInfo = getNavigationInfo(tool.toolId);
        const icon = toolInfo.icon;

        return (
          <NavigationItemCompact
            key={tool.toolId}
            onClick={() => onNavigationClick(tool.toolId)}
            icon={icon}
            tooltip={intl.formatMessage({ id: toolInfo.title })}
            isActive={activePage === tool.toolId}
          />
        );
      })}
      <div className="wp-navigation-separator" />
      <NavigationItemCompact
        icon="logout"
        tooltip={intl.formatMessage({ id: 'translate.wm.navigation.logout' })}
        onClick={onLogOut}
      />
    </Grid>
  </Navigation>
);

export default injectIntl(NavigationListCompact);
