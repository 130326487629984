import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import { WM_REDUX_FORM } from '../../../core/constants/common';
import { SGForm, SGSubmitButton } from '../../../../web/components/sg-form';
import { withSpanelForm } from '../../../../web/components/sg-spanel-forms';
import AutoResponderFields from '../autoresponder-fields';

type Props = {
  change: Function;
  getFormValues: (formName) => any;
  validationUtils: ValidationUtils;
  email: string;
  sPanelFormSubmit: (formName) => null;
};

const formName = WM_REDUX_FORM.CREATE_AUTO_RESPONDER_WEB_MAIL;

const Form = ({ getFormValues, validationUtils, change, email, sPanelFormSubmit }: Props) => (
  <SGForm onSubmit={sPanelFormSubmit(formName)}>
    <AutoResponderFields
      values={getFormValues(formName)}
      email={email}
      changeFieldValue={change}
      validationUtils={validationUtils}
    />

    <Column smSpan="12">
      <SGSubmitButton formName={formName} />
    </Column>
  </SGForm>
);

const EmailAutoResponderForm = reduxForm({ form: formName })(Form);

export default withSpanelForm(EmailAutoResponderForm);
