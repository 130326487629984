import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import FormInput from '../../../../web/components/form-input';
import FormPassword from '../../../../web/components/form-password';
import { SGForm, SGSubmitButton } from '../../../../web/components/sg-form';
import { withSpanelForm } from '../../../../web/components/sg-spanel-forms';
import { WM_REDUX_FORM } from '../../../core/constants/common';

type Props = {
  domain: any[];
  intl: Intl;
  change: Function;
  getFormValues: Function;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => void;
};

const CONFIRM_PASSWORD_NAME = 'confirm-password';
const formName = WM_REDUX_FORM.CHANGE_EMAIL_PASSWORD;

const Form = ({ intl, change, validationUtils, sPanelFormSubmit, getFormValues }: Props) => {
  const [passwordVisibility, setPasswordVisibility] = React.useState(false);
  const { required, password, equalToPassword } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="6">
        <Field
          name="password"
          type="password"
          label={intl.formatMessage({ id: 'translate.wm.login.credentials.new.password' })}
          validate={[required, password]}
          onGenerate={(pass) => {
            change(CONFIRM_PASSWORD_NAME, pass);
            setPasswordVisibility(true);
          }}
          component={FormPassword}
        />
      </Column>

      <Column smSpan="6">
        <Field
          name={CONFIRM_PASSWORD_NAME}
          type="password"
          label={intl.formatMessage({ id: 'translate.wm.login.credentials.confirm.password' })}
          validate={[required, equalToPassword]}
          component={FormInput}
          visiblePassword={passwordVisibility}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.submit' })} />
      </Column>
    </SGForm>
  );
};

const ChangePassword = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.EMAIL,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(ChangePassword));
