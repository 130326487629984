export const PAGE_LOAD = 'PAGE_LOAD';
export const PAGE_INIT = 'PAGE_INIT';
export const PAGE_RESIZE = 'PAGE_RESIZE';

// REQUESTS
export const HTTP_REQUEST_STARTED = 'HTTP_REQUEST_STARTED';
export const HTTP_REQUEST_SUCCEEDED = 'HTTP_REQUEST_SUCCEEDED';
export const HTTP_REQUEST_FAILED = 'HTTP_REQUEST_FAILED';
export const REQUEST_DATA = 'REQUEST_DATA';
export const REQUEST_NEMO_DATA = 'REQUEST_NEMO_DATA';

// Menu Items
export const NAVIGATION_CHANGE = 'NAVIGATION_CHANGE';

// Sites
export const STORE_SITES = 'STORE_SITES';

// CRUD Action
export const ADD_ITEM = 'ADD_ITEM';
export const GET_ITEM = 'GET_ITEM';
export const GET_ITEMS = 'GET_ITEMS';
export const EDIT_ITEM = 'EDIT_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';

export const LOAD_TRANSLATIONS = 'LOAD_TRANSLATIONS';

// Meta action for clearing resource from pageItems
export const CLEAR_RESOURCE = 'CLEAR_RESOURCE';

// Client Token Session
export const VERIFY_USER = 'VERIFY_USER';
export const SIGN_IN = 'SIGN_IN';
export const STORE_SESSION_DATA = 'STORE_SESSION_DATA';
export const STORE_SESSION_TOKEN = 'STORE_SESSION_TOKEN';
export const STORE_SESSION_PROFILE = 'STORE_SESSION_PROFILE';

export const REFRESH_SITE_TOKEN = 'REFRESH_SITE_TOKEN';
export const SAVE_SITE_TOKEN = 'SAVE_SITE_TOKEN';
export const REFRESH_SITE_TOKEN_FAILED = 'REFRESH_SITE_TOKEN_FAILED';

export const REFRESH_CLIENT_TOKEN = 'REFRESH_CLIENT_TOKEN';
export const REFRESH_CLIENT_TOKEN_SUCCEEDED = 'REFRESH_CLIENT_TOKEN_SUCCEEDED';
export const REFRESH_CLIENT_TOKEN_FAILED = 'REFRESH_CLIENT_TOKEN_FAILED';

export const PAGE_LOAD_FAILED = 'PAGE_LOAD_FAILED';

export const RETRY_PAGE_LOAD = 'RETRY_PAGE_LOAD';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_FROM_WEBAPP = 'LOGOUT_FROM_WEBAPP';
export const CLEAR_SESSION_DATA = 'CLEAR_SESSION_DATA';
export const CHANGE_CURRENT_SITE = 'CHANGE_CURRENT_SITE';
export const REDIRECT_FROM_REMOTE = 'REDIRECT_FROM_REMOTE';

// Site Token Session
export const CHANGE_CURRENT_DOMAIN_NAME = 'CHANGE_CURRENT_DOMAIN_NAME';
export const SITE_REQUEST_AUTHORIZATION_FAILED = 'SITE_REQUEST_AUTHORIZATION_FAILED';

// Meta Api
export const FETCH_SITEMETAAPI_REQUESTED = 'FETCH_SITEMETAAPI_REQUESTED';
export const FETCH_SITEMETAAPI_SUCCEEDED = 'FETCH_SITEMETAAPI_SUCCEEDED';

// Crud
export const FETCH_ITEMS_REQUESTED = 'FETCH_ITEMS_REQUESTED';
export const FETCH_ITEMS_SUCCEEDED = 'FETCH_ITEMS_SUCCEEDED';
export const FETCH_ITEMS_FAILED = 'FETCH_ITEMS_FAILED';

export const FETCH_ITEM_REQUESTED = 'FETCH_ITEM_REQUESTED';
export const FETCH_ITEM_SUCCEEDED = 'FETCH_ITEM_SUCCEEDED';
export const FETCH_ITEM_FAILED = 'FETCH_ITEM_FAILED';

export const DELETE_ITEM_REQUESTED = 'DELETE_ITEM_REQUESTED';
export const DELETE_ITEM_SUCCEEDED = 'DELETE_ITEM_SUCCEEDED';
export const DELETE_ITEM_FAILED = 'DELETE_ITEM_FAILED';

export const CREATE_ITEM_REQUESTED = 'CREATE_ITEM_REQUESTED';
export const CREATE_ITEM_SUCCEEDED = 'CREATE_ITEM_SUCCEEDED';
export const CREATE_ITEM_FAILED = 'CREATE_ITEM_FAILED';

export const UPDATE_ITEM_REQUESTED = 'UPDATE_ITEM_REQUESTED';
export const UPDATE_ITEM_SUCCEEDED = 'UPDATE_ITEM_SUCCEEDED';
export const UPDATE_ITEM_FAILED = 'UPDATE_ITEM_FAILED';

export const CHANGE_CURRENT_EDIT_ENTITY = 'CHANGE_CURRENT_EDIT_ENTITY';

// Page action
export const UPDATE_EMAIL_FILTERS_ORDER = 'UPDATE_EMAIL_FILTERS_ORDER';
export const TRIGGER_PAGE_DATA_FETCH = 'TRIGGER_PAGE_DATA_FETCH';

// Notifications
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CREATE_TASK_NOTIFICATION = 'CREATE_TASK_NOTIFICATION';
export const REMOVE_TASK_NOTIFICATION = 'REMOVE_TASK_NOTIFICATION';

// TASKS
export const LOAD_BACKGROUND_TASKS = 'LOAD_BACKGROUND_TASKS';
export const FETCH_PENDING_TASKS_REQUESTED = 'FETCH_PENDING_TASKS_REQUESTED';
export const CHECKING_TASK_REQUESTED = 'CHECKING_TASK_REQUESTED';
export const PENDING_TASK_COMPLETED = 'PENDING_TASK_COMPLETED';
export const PENDING_TASK_MISSING = 'PENDING_TASK_MISSING';
export const ADD_TASK = 'ADD_TASK';
export const REQUEST_STARTED_BACKGROUND_TASK = 'REQUEST_STARTED_BACKGROUND_TASK';
export const INITIAL_TASKS_LOADED = 'INITIAL_TASKS_LOADED';

// Internationalization
export const CHANGE_I18N = 'CHANGE_I18N';
export const DISABLE_ALL_DOMAIN_CACHE = 'DISABLE_ALL_DOMAIN_CACHE';

// User preferences
export const UPDATE_USER_LANGUAGE = 'UPDATE_USER_LANGUAGE';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';

// CDN
export const UPDATE_CDN_ITEM_REQUESTED = 'UPDATE_CDN_ITEM_REQUESTED';
export const CREATE_CDN_ITEM_REQUESTED = 'CREATE_CDN_ITEM_REQUESTED';

// Cloudflare
export const SET_REDIRECT_INFORMATION_FOR_CLOUDFLARE = 'SET_REDIRECT_INFORMATION_FOR_CLOUDFLARE';
export const CLEAR_REDIRECT_INFORMATION_FOR_CLOUDFLARE = 'CLEAR_REDIRECT_INFORMATION_FOR_CLOUDFLARE';

// Wp Staging Actions
export const REQUEST_UNKNOWN_FILES = 'REQUEST_UNKNOWN_FILES';

// Wp Autoupdate Actions
export const ADD_SITE_MANUALLY = 'ADD_SITE_MANUALLY';

// Email Accounts Actions
export const REQUEST_AUTOCONFIGURE_DATA = 'REQUEST_AUTOCONFIGURE_DATA';
export const REQUEST_AUTO_LOGIN_IN_WEBAPP = 'REQUEST_TOKEN_FOR_WEBAPP';

// Email Authentication Actions
export const UPDATE_EMAIL_AUTHENTICATION_SPF = 'UPDATE_EMAIL_AUTHENTICATION_SPF';

// Statistics Data Actions
export const DOMAIN_STATS_REQUESTED = 'DOMAIN_STATS_REQUESTED';
export const DOMAIN_STATS_SUCCEEDED = 'DOMAIN_STATS_SUCCEEDED';
export const DOMAIN_STATS_FAILED = 'DOMAIN_STATS_FAILED';

// load countries from nemo
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCEEDED = 'GET_COUNTRIES_SUCCEEDED';

export const SUBSCRIBE_FOR_TASK_COMPLETION = 'SUBSCRIBE_FOR_TASK_COMPLETION';

export const GET_BACKUP_ORDER_DATA = 'GET_BACKUP_ORDER_DATA';
export const BACKUP_ORDER_DATA_LOADED = 'BACKUP_ORDER_DATA_LOADED';
export const CREATE_BACKUP = 'CREATE_BACKUP';
export const CONFIRM_BACKUP_UPGRADE = 'CONFIRM_BACKUP_UPGRADE';
export const CANCEL_BACKUP_UPGRADE = 'CANCEL_BACKUP_UPGRADE';

// SG SiteScanner
export const REQUEST_SG_SITESCANNER_DATA = 'REQUEST_SG_SITESCANNER_DATA';
export const FORCE_SCAN = 'FORCE_SCAN';
export const CHECK_REPORT_STATUS = 'CHECK_REPORT_STATUS';
export const STORE_SITESCANNER_DATA = 'STORE_SITESCANNER_DATA';
export const REQUEST_SG_SITESCANNER_REPORTS = 'REQUEST_SG_SITESCANNER_REPORTS';
export const SET_SG_SITESCANNER_REPORTS = 'SET_SG_SITESCANNER_REPORTS';

export const SAVE_REDIRECT_DATA = 'SAVE_REDIRECT_DATA';

// Pages
export const SET_MAINTENANCE_PAGE = 'SET_MAINTENANCE_PAGE';
