import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Container, Flex, Grid, Icon, Link, Section, Title } from '@siteground/styleguide';

import { closeSGDialog, openSGDialog } from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS } from '../../../core/constants/common';
import { WmToolId } from '../../../core/constants/route-info';
import { findMainDomain } from '../../../core/selectors';
import { DeleteDialog } from '../../../web/components/dialogs';
import indexWithCRUD from '../../../web/components/indexWithCRUD';
import PageHeader from '../../../web/components/page-header/page-header';
import PartialLoader from '../../../web/components/partial-loader/partial-loader';
import { SGDialogForm } from '../../../web/components/sg-dialog';
import SGTable from '../../../web/components/sg-table';
import TableContextMenu from '../../../web/components/table-context-menu/table-context-menu';
import { SecondLevelTitle } from '../../../web/components/titles';
import { WM_REDUX_FORM } from '../../core/constants/common';
import { getMainEmail } from '../../core/selectors/email';

import { CreateForm, UpdateForm } from './create';

type StoreProps = {
  mainDomain: any;
  email: any;
};

type DispatchProps = {
  openSGDialog: typeof openSGDialog;
  closeSGDialog: typeof closeSGDialog;
  intl: Intl;
};

type OwnProps = {
  items: any;
  actions: CrudActions;
};

type Props = StoreProps & DispatchProps & OwnProps;

type ForwardEntity = {
  is_discard: number;
  destination: string[];
  id: number;
  name: string;
  discard_msg?: string;
  domain_name: string;
};

type State = {
  currentDeletePayload: DeleteItemPayload;
  currentUpdatePayload: ForwardEntity & UpdateItemPayload;
};

class MailForwarders extends React.PureComponent<Props, State> {
  state = {
    currentDeletePayload: null,
    currentUpdatePayload: null
  };

  onCreateFormSubmit = (data) => {
    const { actions, closeSGDialog } = this.props;

    let modifiedData: any = {
      name: this.props.email.name,
      is_discard: Number(data.type === 'discard'),
      domain_id: this.props.mainDomain.id,
      _metaFields: data._metaFields
    };

    if (modifiedData.is_discard) {
      modifiedData = {
        ...modifiedData,
        discard_msg: data.discard_msg
      };
    } else {
      modifiedData = {
        ...modifiedData,
        destination: data.destination
      };
    }

    const rule = data.type === 'discard' ? 'Discard' : 'Forward';

    actions.createItem(
      {
        ...modifiedData,
        _meta: {
          notification: {
            type: 'generic',
            success: {
              intlKey: 'translate.wm.mail.forwarders.type-of-rule.created.text',
              intlValues: { rule }
            },
            error: {
              intlKey: 'translate.wm.mail.forwarders.type-of-rule.failed-creation.text',
              intlValues: { rule }
            }
          }
        }
      },
      () => closeSGDialog(WM_REDUX_FORM.CREATE_FORWARDERS_WEB_MAIL)
    );
  };

  render() {
    const { intl } = this.props;

    return (
      <React.Fragment>
        <PageHeader
          id={WmToolId.wmEmailForward}
          instructions={intl.formatMessage({ id: 'translate.wm.mail.forwarders.info' })}
        />

        <Section>
          <Grid>
            {this.renderCreateNotice()}

            {this.renderTable()}

            {this.renderCreateRuleDialog()}
            {this.renderUpdateDialog()}
            {this.renderDeleteDialog()}
          </Grid>
        </Section>
      </React.Fragment>
    );
  }

  renderCreateNotice() {
    const { intl, openSGDialog, items } = this.props;
    const data = items.emailForward || [];
    const [forwardItem] = data;

    if (forwardItem) {
      return null;
    }

    return (
      <PartialLoader
        hideContent
        resources={[
          { resourceName: API_RESOURCE.EMAIL.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.EMAIL_FORWARD.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.DOMAIN_ALL.resourceName, methods: ['GET'] }
        ]}
      >
        <div>
          <SecondLevelTitle>
            {intl.formatMessage({ id: 'translate.wm.mail.forwarders.active-rule.title' })}
          </SecondLevelTitle>

          <Container>
            <Grid>
              <Flex justify="center" gutter="medium" padding="none">
                <Icon name="information" color="primary" size="40" />
              </Flex>
              <Flex justify="center" gutter="medium" padding="none">
                <Title>
                  {intl.formatMessage({
                    id: 'translate.wm.email-forward.no-rule.text'
                  })}
                </Title>
              </Flex>
              <Flex justify="center" gutter="medium" padding="none">
                <Button color="primary" onClick={() => openSGDialog(WM_REDUX_FORM.CREATE_FORWARDERS_WEB_MAIL)}>
                  {intl.formatMessage({ id: 'translate.generic.create' })}
                </Button>
              </Flex>
            </Grid>
          </Container>
        </div>
      </PartialLoader>
    );
  }

  renderTable() {
    const { items } = this.props;
    const data = items.emailForward || [];
    const [forwardItem] = data;

    if (!forwardItem) {
      return null;
    }

    return forwardItem.is_discard ? this.renderDiscardTable({ data }) : this.renderForwardTable({ data });
  }

  renderCreateRuleDialog() {
    const { intl } = this.props;

    return (
      <SGDialogForm
        name={WM_REDUX_FORM.CREATE_FORWARDERS_WEB_MAIL}
        state="active"
        icon="plus"
        title={intl.formatMessage({ id: 'translate.wm.email-forward.dialog.new.rule.title' })}
        resources={[
          {
            resourceName: API_RESOURCE.EMAIL_FORWARD.resourceName,
            methods: ['POST']
          }
        ]}
      >
        <CreateForm onSubmit={this.onCreateFormSubmit} />
      </SGDialogForm>
    );
  }

  renderUpdateDialog = () => {
    const { intl, email, actions, closeSGDialog } = this.props;
    const { currentUpdatePayload } = this.state;
    const { is_discard = 0, destination = [], discard_msg = '' } = currentUpdatePayload || {};
    const rule = Boolean(currentUpdatePayload && currentUpdatePayload.is_discard)
      ? intl.formatMessage({ id: 'translate.wm.email-forward.discard.label' })
      : intl.formatMessage({ id: 'translate.wm.email-forward.foward.label' });

    const meta = {
      notification: {
        type: 'generic',
        success: {
          intlKey: 'translate.wm.email-forward.update.success.message',
          intlValues: { rule }
        },
        error: {
          intlKey: 'translate.wm.email-forward.update.failed.message',
          intlValues: { rule }
        }
      }
    };

    return (
      <SGDialogForm
        name={WM_REDUX_FORM.UPDATE_FORWARDERS_WEB_MAIL}
        state="active"
        icon="edit"
        title={intl.formatMessage({ id: 'translate.wm.emailForward.dialog.edit.rule.title' })}
        resources={[
          {
            resourceName: API_RESOURCE.EMAIL_FORWARD.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <UpdateForm
          initialValues={{
            type: Boolean(is_discard) ? 'discard' : 'forward',
            destination,
            discard_msg
          }}
          onSubmit={(data) => {
            let modifiedData: any = {
              name: email.name,
              is_discard: Number(data.type === 'discard')
            };

            if (modifiedData.is_discard) {
              modifiedData = {
                ...modifiedData,
                discard_msg: data.discard_msg
              };
            } else {
              modifiedData = {
                ...modifiedData,
                destination: data.destination
              };
            }

            actions.updateItem(
              {
                _meta: meta,
                ...currentUpdatePayload,
                ...modifiedData
              },
              () => closeSGDialog(WM_REDUX_FORM.UPDATE_FORWARDERS_WEB_MAIL)
            );
          }}
        />
      </SGDialogForm>
    );
  };

  renderContextMenu = (id, entity: ForwardEntity) => {
    const { intl, openSGDialog } = this.props;

    const rule = Boolean(entity.is_discard)
      ? intl.formatMessage({ id: 'translate.wm.email-forward.foward.label' })
      : intl.formatMessage({ id: 'translate.wm.email-forward.discard.label' });

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      _metaFields: { ...API_RESOURCE.EMAIL_FORWARD },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.wm.email-forward.delete.success.message',
            intlValues: { rule }
          },
          error: {
            intlKey: 'translate.wm.email-forward.delete.failed.message',
            intlValues: { rule }
          }
        }
      },
      entity
    };

    const currentUpdatePayload: ForwardEntity & UpdateItemPayload = {
      ...entity,
      _metaFields: { ...API_RESOURCE.EMAIL_FORWARD }
    };

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.EMAIL_FORWARD.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.generic.edit' }),
            e2eAttr: `table-action-edit-${entity.is_discard ? 'discard' : 'forwarder'}`,
            icon: 'edit',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentUpdatePayload }, () => openSGDialog(WM_REDUX_FORM.UPDATE_FORWARDERS_WEB_MAIL))
          },
          {
            vcsMethod: 'DELETE',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: `table-action-delete-${entity.is_discard ? 'discard' : 'forwarder'}`,
            icon: 'trash',
            visibleOnDesktop: true,
            onClick: () =>
              this.setState({ currentDeletePayload: deletePayload }, () => openSGDialog(DIALOGS.GENERIC_DELETE))
          }
        ]}
      />
    );
  };

  renderDeleteDialog() {
    const { intl, actions } = this.props;
    const { currentDeletePayload } = this.state;

    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.wm.email-forward.delete.title' })}
        onSubmit={() => actions.deleteItem(currentDeletePayload)}
      />
    );
  }

  renderForwardTable = ({ data }) => {
    const { intl, openSGDialog } = this.props;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.wm.mail.forwarders.type' }),
        accessor: 'is_discard',
        render: () => 'Forward'
      },
      {
        header: intl.formatMessage({ id: 'translate.wm.mail.forwarders.forward-to' }),
        accessor: 'destination',
        render: (destination, entity) => {
          const destinationMails = new Set(entity.destination);

          return (
            <FormattedMessage
              id="translate.wm.mail.forwarders.forward.destination.text"
              values={{
                destination: (
                  <Link
                    onClick={() => {
                      this.setState(
                        {
                          currentUpdatePayload: {
                            ...entity,
                            _metaFields: { ...API_RESOURCE.EMAIL_FORWARD }
                          }
                        },
                        () => openSGDialog(WM_REDUX_FORM.UPDATE_FORWARDERS_WEB_MAIL)
                      );
                    }}
                  >
                    {destinationMails.size === 1
                      ? entity.destination[0]
                      : intl.formatMessage(
                          { id: 'translate.wm.mail.forwarders.forward.destination.multiple.text' },
                          { count: destinationMails.size }
                        )}
                  </Link>
                )
              }}
            />
          );
        }
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <SGTable
        title={intl.formatMessage({ id: 'translate.wm.mail.forwarders.active-rule.title' })}
        data={data}
        columns={columns}
        resources={[
          {
            resourceName: API_RESOURCE.EMAIL_FORWARD.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.EMAIL.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
            methods: ['GET']
          }
        ]}
        noDataMessage="translate.page.wm.mail.forwarders.sg-table.no-data.message"
      />
    );
  };

  renderDiscardTable = ({ data }) => {
    const { intl } = this.props;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.wm.mail.forwarders.type' }),
        accessor: 'is_discard',
        render: () => 'Discard'
      },
      {
        header: intl.formatMessage({ id: 'translate.wm.mail.forwarders.discard-message.label' }),
        accessor: 'discard_msg',
        render: (discardMsg) => discardMsg
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <SGTable
        title={intl.formatMessage({ id: 'translate.wm.mail.forwarders.active-rule.title' })}
        data={data}
        columns={columns}
        resources={[
          {
            resourceName: API_RESOURCE.EMAIL_FORWARD.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.EMAIL.resourceName,
            methods: ['GET']
          },
          {
            resourceName: API_RESOURCE.DOMAIN_ALL.resourceName,
            methods: ['GET']
          }
        ]}
        noDataMessage="translate.page.wm.mail.forwarders.sg-table.no-data.message"
      />
    );
  };
}

const mapStoreToProps = (store) => ({
  mainDomain: findMainDomain(store, API_RESOURCE.DOMAIN_ALL.resourceName),
  email: getMainEmail(store)
});

export default indexWithCRUD(mapStoreToProps, { openSGDialog, closeSGDialog })(
  MailForwarders,
  API_RESOURCE.EMAIL,
  API_RESOURCE.EMAIL_FORWARD,
  API_RESOURCE.DOMAIN_ALL
);
