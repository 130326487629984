import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FocusScope, Title } from '@siteground/styleguide';
import * as taskSelectors from '../../../core/selectors/pending-tasks';
import { RootState } from '../../reducers';
import GearIcon from './gear-icon';
import './task-loader.scss';
import TaskNotifications from './task-notifications';

type TaskLoaderProps = {
  router?: any;
  location?: {
    pathname: string;
    search: string;
  };
  pendingLocalTasks?: Task[];
  pendingTasks?: Task[];
  removeTask?: Function;
  intl: Intl;
};

class TaskLoader extends React.Component<TaskLoaderProps, any> {
  renderLoader() {
    const { pendingTasks, pendingLocalTasks } = this.props;

    if (pendingTasks.length === 0 || pendingLocalTasks.length > 0) {
      return null;
    }

    return (
      <div className="task-loader-wrapper" data-e2e="long-task-dialog">
        <FocusScope>
          <div className="task-loader">
            <GearIcon />

            <Title level="3" weight="regular" tabIndex={0}>
              {taskSelectors.getTaskTitle(pendingTasks[0])}
            </Title>
          </div>
        </FocusScope>
      </div>
    );
  }

  renderTaskNotifications() {
    return <TaskNotifications pathname={this.props.location.pathname} />;
  }

  render() {
    return (
      <React.Fragment>
        {this.renderLoader()}
        {this.renderTaskNotifications()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ tasks }: RootState, ownProps: TaskLoaderProps) => ({
  pendingTasks: taskSelectors.getPendingTasks({ tasks }, ownProps.location.pathname),
  pendingLocalTasks: taskSelectors.getPendingLocalTasks({ tasks }, ownProps.location.pathname)
});

export default withRouter(connect<{}, any, TaskLoaderProps>(mapStateToProps)(injectIntl(TaskLoader)));
