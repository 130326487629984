import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import FormDropdown from '../../../../web/components/form-dropdown';
import { SGForm, SGSubmitButton } from '../../../../web/components/sg-form';
import { withSpanelForm } from '../../../../web/components/sg-spanel-forms';
import { WM_REDUX_FORM } from '../../../core/constants/common';

type Props = {
  domain: any[];
  intl: Intl;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => void;
};

const formName = WM_REDUX_FORM.CREATE_AUTO_CONFIGURE_WEB_MAIL;

const MAIL_CLIENT_OPTIONS = [
  {
    label: 'translate.wm.mail.config.autoconfigure.option.ios.label',
    value: 'ios'
  },
  {
    label: 'translate.wm.mail.config.autoconfigure.option.macmail.label',
    value: 'macmail'
  },
  {
    label: 'translate.wm.mail.config.autoconfigure.option.macmail_prior.label',
    value: 'macmail_prior'
  },
  {
    label: 'translate.wm.mail.config.autoconfigure.option.outlook.live.mail.label',
    value: 'outlook'
  },
  {
    label: 'translate.wm.mail.config.autoconfigure.option.outlook.windows.label',
    value: 'outlook_windows'
  },
  {
    label: 'translate.wm.mail.config.autoconfigure.option.outlook.express.windows.label',
    value: 'outlook_express'
  }
];

const Form = ({ intl, validationUtils, sPanelFormSubmit }: Props) => {
  const { required } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)}>
      <Column smSpan="12">
        <Field
          name="emailClientId"
          type="text"
          label={intl.formatMessage({ id: 'translate.wm.mail.config.autoconfigure.select.client' })}
          placeholder={intl.formatMessage({ id: 'translate.generic.please_select' })}
          options={MAIL_CLIENT_OPTIONS}
          optionValue="value"
          optionLabel="label"
          template={({ label }) => intl.formatMessage({ id: label })}
          validate={[required]}
          expand
          disableAutoSelect
          component={FormDropdown}
        />
      </Column>

      <Column smSpan="12">
        <SGSubmitButton formName={formName} label={intl.formatMessage({ id: 'translate.generic.generate' })} />
      </Column>
    </SGForm>
  );
};

const LocationIpBlockForm = reduxForm({
  form: formName,
  initialValues: {
    _metaFields: {
      ...API_RESOURCE.EMAIL,
      formName
    }
  }
})(Form);

export default withSpanelForm(injectIntl(LocationIpBlockForm));
