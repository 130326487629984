import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Column, Flex } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import FormEmailDropdown from '../../../../web/components/form-email-dropdown';
import FormInput from '../../../../web/components/form-input';
import FormRadioButton from '../../../../web/components/form-radio-button';
import { SGForm } from '../../../../web/components/sg-form';
import { withSpanelForm } from '../../../../web/components/sg-spanel-forms';
import { WM_REDUX_FORM } from '../../../core/constants/common';

type Props = {
  children: any;
  intl: any;
  getFormValues: (formName) => any;
  validationUtils: ValidationUtils;
  sPanelFormSubmit: (formName) => void;
  form: string;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.EMAIL_FORWARD;
const formName = WM_REDUX_FORM.CREATE_FORWARDERS_WEB_MAIL;

const Form = ({ children, intl, getFormValues, validationUtils, sPanelFormSubmit, form }: Props) => {
  const values = getFormValues(form);
  const { required, emailsPunycode } = validationUtils;

  return (
    <SGForm onSubmit={sPanelFormSubmit(form)}>
      <Column align="flex-start" smSpan="12">
        <Flex direction="column" style={{ textAlign: 'start' }}>
          <Field name="type" type="radio" value="forward" component={FormRadioButton}>
            {intl.formatMessage({ id: 'translate.wm.email-forward.foward.label' })}
          </Field>
          <Field name="type" type="radio" value="discard" component={FormRadioButton}>
            {intl.formatMessage({ id: 'translate.wm.email-forward.discard.label' })}
          </Field>
        </Flex>
      </Column>

      {values.type === 'forward' ? (
        <Column smSpan="12">
          <Field
            data-e2e="forward-crate-email_select_visual"
            name="destination"
            type="text"
            label={intl.formatMessage({ id: 'translate.wm.mail-forward.destination' })}
            placeholder={intl.formatMessage({ id: 'translate.wm.mail-forward.destination.placeholder' })}
            validate={[required, emailsPunycode]}
            component={FormEmailDropdown}
          />
        </Column>
      ) : (
        <Column smSpan="12">
          <Field
            data-e2e="forward-crate-discard_msg"
            name="discard_msg"
            type="text"
            label={intl.formatMessage({ id: 'translate.wm.mail-forward.discard_msg' })}
            placeholder={intl.formatMessage({ id: 'translate.wm.mail-forward.discard_msg.placeholder' })}
            validate={[required]}
            component={FormInput}
          />
        </Column>
      )}
    </SGForm>
  );
};

export const CreateForm = withSpanelForm(
  injectIntl(
    reduxForm({
      form: WM_REDUX_FORM.CREATE_FORWARDERS_WEB_MAIL,
      initialValues: {
        type: 'forward',
        _metaFields: {
          ...API_RESOURCE.EMAIL_FORWARD,
          formName
        }
      }
    })(Form)
  )
);

export const UpdateForm = withSpanelForm(
  injectIntl(
    reduxForm({
      form: WM_REDUX_FORM.UPDATE_FORWARDERS_WEB_MAIL
    })(Form)
  )
);
