import * as React from 'react';
import { TitleOne, TitleProps } from '@siteground/styleguide';

const FirstLevelTitle = ({ children, ...props }: Partial<TitleProps>) => {
  return (
    <TitleOne level="1" {...props}>
      {children}
    </TitleOne>
  );
};

export default FirstLevelTitle;
