import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Layout, Page } from '@siteground/styleguide';
import { ErrorPage } from '@siteground/styleguide/lib/composite';
import '@siteground/styleguide/lib/styles/main.scss';

import * as deviceActions from '../../../core/actions/device';
import { openSGDialog } from '../../../core/actions/sg-dialog';
import { DIALOGS } from '../../../core/constants/common';
import { getCurrentSiteToken } from '../../../core/selectors';
import { SendErrorLog } from '../../../core/utils/send-error-log';
import PartialLoader from '../../../web/components/partial-loader';
import TaskLoader from '../../../web/components/task-loader';
import { default as withDrawerState, WithDrawerInjectedProps } from '../../../web/components/with-drawer';
import DebugDialog from '../../../web/pages/app/debug-dialog';

import '../../assets/styles/main.scss';
import { pageLoad } from '../../core/actions/page';
import { PAGE_LOAD_REQUESTS } from '../../core/constants/common';
import Notifications from './../../../web/components/notifications';

import { WMHeader } from './header';
import { WMNavigation } from './navigation';

type StoreProps = {
  session: any;
  lastActions: any;
  i18nMessages: any;
  hasUserSession: boolean;
};

type DispatchProps = {
  onPageInit: typeof deviceActions.onPageInit;
  onPageResize: typeof deviceActions.onPageResize;
  pageLoad: typeof pageLoad;
  openSGDialog: typeof openSGDialog;
};

type OwnProps = {};

type Props = StoreProps & DispatchProps & OwnProps & WithDrawerInjectedProps;

type State = {
  error: string;
  errorInfo: string;
};

class App extends React.Component<Props, State> {
  readonly state = {
    error: null,
    errorInfo: null
  };

  onKeyDownHandler(event) {
    if (event.keyCode === 83 && event.ctrlKey && event.shiftKey && event.altKey) {
      // CTRL + (ALT | OPTION) + SHIFT + S
      this.props.openSGDialog(DIALOGS.DEBUG_DIALOG);
    }
  }

  componentDidMount() {
    this.props.pageLoad();

    window.addEventListener('keydown', this.onKeyDownHandler.bind(this));
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });

    SendErrorLog({
      error,
      errorInfo,
      session: this.props.session,
      lastActions: this.props.lastActions,
      projectName: 'webmail',
      uid: 'webmail.siteground.com'
    });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDownHandler.bind(this));
  }

  renderHeader() {
    const { drawerIsOpen, toggleDrawer } = this.props;

    return <WMHeader open={drawerIsOpen} toggleDrawer={toggleDrawer} />;
  }

  renderDrawer() {
    const { hasUserSession, toggleDrawer } = this.props;

    if (!hasUserSession) {
      return null;
    }

    return <WMNavigation toggleDrawer={toggleDrawer} />;
  }

  renderContent() {
    const { children, i18nMessages } = this.props;

    if (this.state.error) {
      return (
        <ErrorPage
          type="something-went-wrong"
          title={i18nMessages['translate.generic.error.page.title']}
          message={i18nMessages['translate.generic.error.page.subtitle']}
        />
      );
    }

    return <Page>{children}</Page>;
  }

  render() {
    const { i18nMessages } = this.props;
    const messages = i18nMessages;

    return (
      <IntlProvider locale="en" messages={messages}>
        <PartialLoader resources={PAGE_LOAD_REQUESTS} hideContent initialLoadingState>
          <Layout
            onInit={(device) => this.props.onPageInit(device)}
            onResize={(device) => this.props.onPageResize(device)}
            drawerContent={this.renderDrawer()}
            isDrawerOpened={false}
            onDrawerOverlayClick={this.props.toggleDrawer}
            headerContent={this.renderHeader()}
            pageContent={this.renderContent()}
            layout="compact"
          >
            <DebugDialog />
          </Layout>

          <Notifications />

          <TaskLoader />
        </PartialLoader>
      </IntlProvider>
    );
  }
}

const mapStoreToProps = (store) => ({
  session: store.session,
  lastActions: store.lastActions,
  dialog: store.dialog,
  i18nMessages: store.i18n.messages,
  i18nShowMessages: store.i18n.showMessages,
  hasUserSession: getCurrentSiteToken(store)
});

export default connect<StoreProps, DispatchProps, OwnProps>(mapStoreToProps, {
  ...deviceActions,
  pageLoad,
  openSGDialog
})(withDrawerState(App));
