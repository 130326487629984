export default {
  VERIFY_USER: 'VERIFY_USER',
  SIGN_IN: 'SIGN_IN',
  VERIFY_PASSWORD_HASH: 'VERIFY_PASSWORD_HASH',
  UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
  CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',

  LOAD_TRANSLATIONS: 'LOAD_TRANSLATIONS',

  SET_USER_LANGUAGE: 'SET_USER_LANGUAGE',
  SET_USER_PREFERENCES: 'SET_USER_PREFERENCES',
  SET_DASHBOARD_PIN_TILES: 'SET_DASHBOARD_PIN_TILES',

  LOGOUT: 'LOGOUT',
  REQUEST_DATA: 'REQUEST_DATA',
  REQUEST_ACCOUNT_UPGRADE_PLANS: 'REQUEST_ACCOUNT_UPGRADE_PLANS',
  CHANGE_CURRENT_SITE: 'CHANGE_CURRENT_SITE',
  PAGE_LOAD: 'PAGE_LOAD',
  NAVIGATING_TO_UA: 'NAVIGATING_TO_UA',
  REDIRECT_FROM_REMOTE: 'REDIRECT_FROM_REMOTE',
  COUNTRIES: 'COUNTRIES',
  ADD_APP_MANUALLY: 'ADD_APP_MANUALLY',
  DOMAIN_STATS: 'DOMAIN_STATS',
  DOMAIN_ALIAS: 'DOMAIN_ALIAS',
  DOMAIN_SUBDOMAIN: 'DOMAIN_SUBDOMAIN',
  EMAIL_AUTOCONFIGURE_DATA: 'EMAIL_AUTOCONFIGURE_DATA',
  EMAIL_FILTER_ORDER: 'EMAIL_FILTER_ORDER',
  EMAIL_AUTHENTICATION_SPF: 'EMAIL_AUTHENTICATION_SPF',
  EMAIL_AUTHENTICATION_SPF_APPROVAL_POLICY: 'EMAIL_AUTHENTICATION_SPF_APPROVAL_POLICY',
  REQUEST_UKNOWN_WP_FILES: 'REQUEST_UKNOWN_WP_FILES',
  REQUEST_CUSTOM_DEPLOY_STAGING: 'REQUEST_CUSTOM_DEPLOY_STAGING',
  REQUEST_REPLICATE_STAGING: 'REQUEST_REPLICATE_STAGING',
  SELECT_DIRECTORY_FETCH: 'SELECT_DIRECTORY_FETCH',
  GET_BACKUP_ORDER_DATA: 'GET_BACKUP_ORDER_DATA',
  MEMCACHE_FLUSH_ALL: 'MEMCACHE_FLUSH_ALL',
  FETCH_SITE_META_API: 'FETCH_SITE_META_API',
  BACKUP_FETCH_SITE_META_API: 'BACKUP_FETCH_SITE_META_API',
  GET_WEBMAIL_LOGIN_DATA: 'GET_WEBMAIL_LOGIN_DATA',

  // SG_SITESCNNER Requests
  GET_SITE_SCANNER_UPGRADES: 'GET_SITE_SCANNER_UPGRADES',
  GET_SITE_SCANNER_REPORTS: 'GET_SITE_SCANNER_REPORTS',
  GET_SITE_SCANNER_REPORTS_STATUS: 'GET_SITE_SCANNER_REPORTS_STATUS',
  GET_QUARANTINE_FILES: 'GET_QUARANTINE_FILES',
  GET_IS_QUARANTINE_ENABLED: 'GET_IS_QUARANTINE_ENABLED',
  SET_QUARANTINE_ENABLED: 'SET_QUARANTINE_ENABLED',
  RESTORE_QUARANTINE_FILES: 'RESTORE_QUARANTINE_FILES',

  GET_WP_LOGIN_URL: 'GET_WP_LOGIN_URL',
  DISABLE_WP_PLUGINS: 'DISABLE_WP_PLUGINS',
  REQUEST_FORCE_SCAN: 'REQUEST_FORCE_SCAN',
  REQUEST_SET_SG_SITESCANNER_NOTIFICATIONS: 'REQUEST_SET_SG_SITESCANNER_NOTIFICATIONS',
  CHECK_ORDER_STATUS: 'CHECK_ORDER_STATUS',
  GET_INSTALLED_EXTRAS: 'GET_INSTALLED_EXTRAS',
  GET_DOMAIN_DNS: 'GET_DOMAIN_DNS',
  GET_CDN_ORDER: 'GET_CDN_ORDER',
  UPDATE_TTL: 'UPDATE_TTL',
  UPDATE_ALWAYS_ONLINE: 'UPDATE_ALWAYS_ONLINE',
  UPDATE_DEV_MODE: 'UPDATE_DEV_MODE',

  FILE_MANAGER_DELETE: 'FILE_MANAGER_DELETE',
  FILE_MANAGER_POST: 'FILE_MANAGER_POST',
  FILE_MANAGER_MOVE_REQUEST: 'FILE_MANAGER_MOVE_REQUEST',
  FILE_MANAGER_SAVE: 'FILE_MANAGER_SAVE',
  FILE_MANAGER_FETCH_FILE: 'FILE_MANAGER_FETCH_FILE',
  FILE_MANAGER_FETCH_DIR: 'FILE_MANAGER_FETCH_DIR',
  FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZES: 'FILE_MANAGER_FETCH_DIR_WITH_FOLDER_SIZE',
  FILE_MANAGER_REQUESTED_NAVIGATE: 'FILE_MANAGER_REQUESTED_NAVIGATE',
  FILE_MANAGER_REQUESTED_SEARCH: 'FILE_MANAGER_REQUESTED_SEARCH',
  FILE_MANAGER_SHOW_ENTITY_IN_EXPLORER: 'FILE_MANAGER_SHOW_ENTITY_IN_EXPLORER',
  FILE_MANAGER_OPEN_ENTITY_IN_EXPLORER: 'FILE_MANAGER_OPEN_ENTITY_IN_EXPLORER',

  REQUEST_INSTALL_WEEBLY: 'REQUEST_INTSALL_WEEBLY',
  REQUEST_WEEBLY_LOGIN: 'REQUEST_WEEBLY_LOGIN',
  SUPPORT_LOADING: 'SUPPORT_LOADING',
  RETRY_PAGE_LOAD: 'RETRY_PAGE_LOAD',

  // WEBMAIL REQUESTS
  WEBMAIL_LOGIN: 'WEBMAIL/LOGIN',
  WEBMAIL_FETCH_SITE_META_API: 'WEMAIL/FETCH_SITE_META_API',
  WEBMAIL_LOAD_IFRAME: 'WEMAIL/LOAD_IFRAME',
  WEBMAIL_FETCH_TRANSLATIONS: 'WEBMAIL/FETCH_TRANSLATIONS',
  LOGOUT_FROM_WM: 'LOGOUT_FROM_WM',

  GET_AVALON_SETTINGS: 'GET_AVALON_SETTINGS',
  SET_AVALON_SETTING: 'SET_AVALON_SETTING'
};
