import { browserHistory } from 'react-router';
import { call, put, select, take } from 'redux-saga/effects';
import * as i18nActions from '../../../core/actions/i18n';
import { fetchSiteMetaApi } from '../../../core/actions/site-meta-api';
import { loadBackgroundTasks } from '../../../core/actions/tasks';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { WmToolId } from '../../../core/constants/route-info';
import { getCurrentSiteToken } from '../../../core/selectors';
import { WM_ROUTES } from '../../core/constants/WM_ROUTES';
import * as pageActions from '../actions/page';
import { onUserLogged } from './login';

export default function* pageLoad() {
  const action = yield take(pageActions.ACTIONS.pageLoad);

  let state = yield select();
  const urlQuery = state.routing.locationBeforeTransitions.query;
  const siteTokenFromRedirect = urlQuery && urlQuery.token;

  if (siteTokenFromRedirect) {
    browserHistory.push({
      pathname: state.routing.locationBeforeTransitions.pathname,
      search: ''
    });

    yield call(onUserLogged, { web_app_token: siteTokenFromRedirect });
  }

  state = yield select();
  const siteToken = getCurrentSiteToken(state);

  yield put(i18nActions.loadTranslations({ fileName: 'en' }));

  if (siteToken) {
    yield put(fetchSiteMetaApi({ requestTypeName: customRequestTypes.WEBMAIL_FETCH_SITE_META_API }));
    yield put(loadBackgroundTasks());

    return;
  }

  browserHistory.push(WM_ROUTES[WmToolId.wmLogin]);
}
