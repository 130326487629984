export function getRcmailIframe() {
  const frames: any = window.frames;
  let iframe;

  for (let i = 0; i < frames.length; i++) {
    try {
      if (frames[i].rcmail) {
        iframe = frames[i];
      }
    } catch (e) {
      console.warn(e);
    }
  }

  return iframe;
}
