import { RootState } from '../../web/reducers';
import { DOMAIN_TYPES } from '../constants/domains';
import { API_RESOURCE } from '../constants/api';
import { isTemporaryDomain } from '../../web/pages/dns/utils';

/* Domain data store selectors */
export const findMainDomain = ({ pageItems }: Partial<RootState>, resourceName = API_RESOURCE.DOMAIN.resourceName) =>
  pageItems[resourceName] ? pageItems[resourceName].find((domain) => domain.main === 1) : null;

export const filterStagingDomains = (
  { pageItems }: Partial<RootState>,
  resourceName = API_RESOURCE.DOMAIN.resourceName
) => (pageItems[resourceName] ? pageItems[resourceName].filter(({ settings }) => settings && !settings.staging) : []);

export const getSpamExpertsDomains = ({ pageItems }: Partial<RootState>) => {
  const allDomains = pageItems[API_RESOURCE.DOMAIN_ALL.resourceName];
  const availableTypes = [DOMAIN_TYPES.main, DOMAIN_TYPES.alias];

  if (allDomains) {
    return allDomains.filter(({ domain_type, name }) =>
      Boolean(availableTypes.includes(domain_type) && !isTemporaryDomain(name))
    );
  }

  return [];
};

export const hasDomainWithCdnMigration = ({ pageItems }: Partial<RootState>) => {
  const allDomains = pageItems[API_RESOURCE.DOMAIN_ALL.resourceName];
  return Boolean(allDomains?.find((domain) => Boolean(domain?.extra?.cdn_migrated)));
};
