import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import { Button, Column, Context, ContextMenu, ContextMenuItem, Grid, IconButton, Text } from '@siteground/styleguide';
import { closeSGDialog, openSGDialog } from '../../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../../core/constants/api';
import { DIALOGS } from '../../../../core/constants/common';
import FormDropdown from '../../../../web/components/form-dropdown';
import FormInput from '../../../../web/components/form-input';
import { SGDialog, SGDialogCancel } from '../../../../web/components/sg-dialog';
import EmailDirectorySelect from '../../../../web/pages/email-filters/email-directory-select';
import { getMainEmail } from '../../../core/selectors/email';
import { comparisionOptions, matchOptions, subjectOptions, userActionOptions } from '../fields-options';

type StoreProps = {
  email: any;
};

type DispatchProps = {
  openSGDialog: typeof openSGDialog;
  closeSGDialog: typeof closeSGDialog;
  intl: Intl;
};

type OwnProps = {
  validationUtils: ValidationUtils;
  change: Function;
  values: any;
};

type Props = StoreProps & DispatchProps & OwnProps;

type State = {
  selectEmailFolderDialog: any;
  selectedPath: string;
};

const { Fragment } = React;

const hasValues = (values) => {
  if (!values) {
    return false;
  }

  return Boolean(Object.keys(values).length);
};

class FilterField extends React.Component<Props, State> {
  readonly state = {
    selectEmailFolderDialog: null,
    selectedPath: 'INBOX'
  };

  getTranslations = (options) =>
    options.map((option) => ({
      ...option,
      label: this.props.intl.formatMessage({ id: option.label })
    }));

  filterValueFields(field) {
    return field !== 'discard' && field !== 'no_more_filters';
  }

  renderCreateFilter() {
    const { validationUtils } = this.props;

    return (
      <Fragment>
        <Column xsSpan="10" smSpan="10">
          <Field
            name="filter_name"
            type="text"
            label={this.props.intl.formatMessage({ id: 'translate.wm.emailFilter.filter.name' })}
            validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column className="wm-email-filters-match-conditionss" xsSpan="10" smSpan="10" lgSpan="11">
          <Field
            name="match_cond"
            options={this.getTranslations(matchOptions)}
            optionValue="value"
            optionLabel="label"
            style={{ display: 'inline-block', width: 'auto' }}
            data-e2e="match-condition"
            component={FormDropdown}
          />

          <Text weight="bold" align="left">
            {this.props.intl.formatMessage({ id: 'translate.wm.emailFilter.filter.conditions.title' })}
          </Text>
        </Column>
      </Fragment>
    );
  }

  renderCreateFilterConditions = (data) => {
    const { values, intl, validationUtils } = this.props;
    const fields = data.fields;

    return fields.map((name, index, array) => {
      const shouldRenderConditions = hasValues(values)
        ? values.conditions[index] && values.conditions[index].field !== 'is_err'
        : true;

      return (
        <Fragment key={name}>
          <Column xsSpan="10" smSpan="10" lgSpan="10">
            <Grid sm="12" gap="large" className="wm-email-filters-condition-grid">
              <Column smSpan="6">
                <Field
                  name={`${name}.field`}
                  type="text"
                  options={this.getTranslations(subjectOptions)}
                  optionValue="value"
                  optionLabel="label"
                  data-e2e="subject"
                  component={FormDropdown}
                />
              </Column>

              {shouldRenderConditions && (
                <Column smSpan="6">
                  <Field
                    name={`${name}.expr`}
                    type="text"
                    options={this.getTranslations(comparisionOptions)}
                    optionValue="value"
                    optionLabel="label"
                    data-e2e="comparision"
                    component={FormDropdown}
                  />
                </Column>
              )}

              {shouldRenderConditions && (
                <Column smSpan="12">
                  <Field
                    name={`${name}.value`}
                    type="text"
                    placeholder={this.props.intl.formatMessage({
                      id: 'translate.wm.emailFilter.condition.criteria.placeholder'
                    })}
                    validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
                    component={FormInput}
                  />
                </Column>
              )}
            </Grid>
          </Column>

          <Context.Consumer>
            {({ device }) => (
              <Column
                xsSpan="1"
                smSpan="2"
                lgSpan="2"
                flex
                align="center"
                justify={device.isPhone ? 'flex-start' : 'center'}
              >
                {device.isPhone
                  ? this.renderMobileConditions(fields, index, array)
                  : this.renderDesktopConditions(fields, index, array)}
              </Column>
            )}
          </Context.Consumer>
        </Fragment>
      );
    });
  };

  renderMobileConditions(fields, index, array) {
    return (
      <ContextMenu opener={<IconButton icon="dots" />}>
        <ContextMenuItem
          icon="circle-plus"
          data-e2e="condition-plus"
          onClick={() =>
            fields.push({
              field: subjectOptions[0].value,
              expr: comparisionOptions[0].value
            })
          }
        >
          {this.props.intl.formatMessage({ id: 'translate.wm.emailFilter.condition.add.new' })}
        </ContextMenuItem>

        <ContextMenuItem
          icon="circle-minus"
          data-e2e="condition-minus"
          onClick={() => {
            if (array.length > 1) {
              fields.remove(index);
            }
          }}
        >
          {this.props.intl.formatMessage({ id: 'translate.wm.emailFilter.condition.remove' })}
        </ContextMenuItem>
      </ContextMenu>
    );
  }

  renderDesktopConditions(fields, index, array) {
    return (
      <React.Fragment>
        <IconButton
          icon="circle-minus"
          data-e2e="condition-minus"
          onClick={() => {
            if (array.length > 1) {
              fields.remove(index);
            }
          }}
        />

        <IconButton
          icon="circle-plus"
          data-e2e="condition-plus"
          onClick={() =>
            fields.push({
              field: subjectOptions[0].value,
              expr: comparisionOptions[0].value
            })
          }
        />
      </React.Fragment>
    );
  }

  renderCreateFilterActions = (data) => {
    const fields = data.fields;
    const { email, change, values, validationUtils } = this.props;

    const validateProp = validationUtils
      ? [validationUtils.required, validationUtils.validationWithMetaApi]
      : undefined;

    return (
      <Fragment>
        <Column xsSpan="12">
          <Text align="left" weight="bold">
            {this.props.intl.formatMessage({ id: 'translate.wm.emailFilter.filter.actions.title' })}
          </Text>
        </Column>

        {fields.map((name, index, array) => {
          const hasFormValues = hasValues(values);
          const actionFieldValue = hasFormValues && values.actions[index] && values.actions[index].action;
          const shouldRenderValueField = this.filterValueFields(actionFieldValue);
          let shouldRenderBrowseSuffix = false;

          let validateFunctions = validateProp;

          if (hasFormValues) {
            const actionField = values.actions[index] && values.actions[index].action;

            if (actionField === 'forward_to' && validationUtils) {
              validateFunctions = [validationUtils.required, validationUtils.email];
            }

            shouldRenderBrowseSuffix = actionField === 'move_to' || actionField === 'copy_to';
          }

          return (
            <React.Fragment key={name}>
              <Column xsSpan="10" smSpan="10" lgSpan="10">
                <Grid sm="12" gap="responsive">
                  <Column smSpan="6">
                    <Field
                      key="user"
                      name={`${name}.action`}
                      type="text"
                      options={this.getTranslations(userActionOptions)}
                      optionValue="value"
                      optionLabel="label"
                      data-e2e="actions"
                      onChange={() => change(`${name}.value`, '')}
                      component={FormDropdown}
                    />
                  </Column>

                  {shouldRenderValueField && (
                    <Column smSpan="6">
                      <Field
                        name={`${name}.value`}
                        type="text"
                        suffix={
                          shouldRenderBrowseSuffix && (
                            <Button
                              action="button"
                              size="small"
                              onClick={() => {
                                this.setState(
                                  { selectEmailFolderDialog: { email, fieldToUpdate: `${name}.value` } },
                                  () => this.props.openSGDialog(DIALOGS.SELECT_EMAIL)
                                );
                              }}
                            >
                              {this.props.intl.formatMessage({ id: 'translate.generic.browse' })}
                            </Button>
                          )
                        }
                        validate={validateFunctions}
                        component={FormInput}
                      />
                    </Column>
                  )}
                </Grid>
              </Column>

              <Context.Consumer>
                {({ device }) => (
                  <Column flex xsSpan="2" smSpan="2" align="center" justify={device.isPhone ? 'flex-start' : 'center'}>
                    {device.isPhone
                      ? this.renderMobileAction(fields, index, array)
                      : this.renderDesktopAction(fields, index, array)}
                  </Column>
                )}
              </Context.Consumer>
            </React.Fragment>
          );
        })}
      </Fragment>
    );
  };

  renderMobileAction(fields, index, array) {
    return (
      <ContextMenu opener={<IconButton icon="dots" />}>
        <ContextMenuItem
          icon="circle-plus"
          data-e2e="action-plus"
          onClick={() => fields.push({ action: userActionOptions[0].value })}
        >
          {this.props.intl.formatMessage({ id: 'translate.wm.emailFilter.action.add.new' })}
        </ContextMenuItem>

        <ContextMenuItem
          icon="circle-minus"
          data-e2e="action-minus"
          onClick={() => {
            if (array.length > 1) {
              fields.remove(index);
            }
          }}
        >
          {this.props.intl.formatMessage({ id: 'translate.wm.emailFilter.action.remove' })}
        </ContextMenuItem>
      </ContextMenu>
    );
  }

  renderDesktopAction(fields, index, array) {
    return (
      <React.Fragment>
        <IconButton
          icon="circle-minus"
          data-e2e="action-minus"
          onClick={() => {
            if (array.length > 1) {
              fields.remove(index);
            }
          }}
        />

        <IconButton
          icon="circle-plus"
          data-e2e="action-plus"
          onClick={() => fields.push({ action: userActionOptions[0].value })}
        />
      </React.Fragment>
    );
  }

  render() {
    const { email, change, closeSGDialog } = this.props;
    const { selectEmailFolderDialog, selectedPath } = this.state;

    return (
      <Fragment>
        {this.renderCreateFilter()}

        <FieldArray name="conditions" component={this.renderCreateFilterConditions} />

        <FieldArray name="actions" component={this.renderCreateFilterActions} />

        <SGDialog
          id={DIALOGS.SELECT_EMAIL}
          title={this.props.intl.formatMessage(
            { id: 'translate.wm.emailFilter.directory.select.title' },
            { name: selectEmailFolderDialog && selectEmailFolderDialog.email.name }
          )}
          icon="folder"
          state="warning"
          footer={
            <div>
              <SGDialogCancel id={DIALOGS.SELECT_EMAIL} />
              <Button
                color="primary"
                action="button"
                data-e2e="dialog-submit"
                onClick={() => {
                  change(selectEmailFolderDialog.fieldToUpdate, selectedPath);
                  closeSGDialog(DIALOGS.SELECT_EMAIL);
                }}
              >
                {this.props.intl.formatMessage({ id: 'translate.generic.select' })}
              </Button>
            </div>
          }
          resources={[{ resourceName: API_RESOURCE.EMAIL_FOLDER.resourceName, methods: ['GET'] }]}
          onCloseHandler={() => this.setState({ selectEmailFolderDialog: null })}
        >
          <EmailDirectorySelect
            selectedFilterEntity={email}
            onSelection={(path) => this.setState({ selectedPath: path })}
          />
        </SGDialog>
      </Fragment>
    );
  }
}

export default connect<StoreProps, any, OwnProps>(
  (store) => ({
    email: getMainEmail(store)
  }),
  {
    openSGDialog,
    closeSGDialog
  }
)(injectIntl<any, any>(FilterField));
