import * as React from 'react';
import { IntlShape } from 'react-intl';
import { Grid, Section } from '@siteground/styleguide';
import { updateEmailFilterOrder } from '../../../core/actions/pages/email-filters';
import { closeSGDialog, openSGDialog } from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS } from '../../../core/constants/common';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { WmToolId } from '../../../core/constants/route-info';
import { moveEntity } from '../../../core/utils/array';
import { DeleteDialog } from '../../../web/components/dialogs';
import indexWithCRUD from '../../../web/components/indexWithCRUD';
import PageHeader from '../../../web/components/page-header';
import { SGDialogForm } from '../../../web/components/sg-dialog';
import SGTable from '../../../web/components/sg-table';
import TableContextMenu from '../../../web/components/table-context-menu/table-context-menu';
import { WM_REDUX_FORM } from '../../core/constants/common';
import { getMainEmail } from '../../core/selectors/email';
import { CreateBox, CreateForm } from './create';
import UpdateFieldsForm from './update/fields';
import './wm-email-filters.scss';

type StoreProps = {
  environment: {
    isPhone: boolean;
    isDevicePhone: boolean;
    isDeviceTablet: boolean;
  };
  email: any;
  emailFilter: any;
};

type DispatchProps = {
  openSGDialog: typeof openSGDialog;
  closeSGDialog: typeof closeSGDialog;
  updateEmailFilterOrder: typeof updateEmailFilterOrder;
};

type OwnProps = {
  intl: Intl;
  actions: CrudActions;
};

type Props = StoreProps & DispatchProps & OwnProps;

type State = {
  currentUpdatePayload: any;
  currentDeleteConformationDialogPayload: any;
};

class EmailFilterPage extends React.Component<Props, State> {
  readonly state = {
    currentUpdatePayload: null,
    currentDeleteConformationDialogPayload: null
  };

  isTouchDevice = () => {
    const { environment } = this.props;

    return environment.isDevicePhone || environment.isDeviceTablet;
  };

  onCreateFormSubmit = (formData) => {
    const { domain_id, id, name } = this.props.email;
    const domainId = domain_id ? domain_id : id;

    formData.name = name;
    formData.domain_id = domainId;

    this.props.actions.createItem({
      ...formData,
      _meta: {
        notification: {
          type: 'form',
          formName: WM_REDUX_FORM.CREATE_EMAIL_FILTER_ITEM,
          success: {
            intlKey: 'translate.wm.emailFilter.created_msg',
            intlValues: { name: formData.filter_name }
          },
          error: {
            intlKey: 'translate.wm.emailFilter.failed_create_msg',
            intlValues: { name: formData.filter_name }
          }
        }
      }
    });
  };

  handleReorderedData = (data) => {
    const { email, updateEmailFilterOrder } = this.props;
    const { domain_id, id, name } = email;
    const domainId = domain_id ? domain_id : id;

    updateEmailFilterOrder({
      name,
      domain_id: domainId,
      filter_ids: data.map((entity) => entity.id),
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.wm.emailFilter.update.filter.order.message',
            intlValues: { account: data.filter_name }
          },
          error: {
            intlKey: 'translate.wm.emailFilter.failed.update.filter.order.message',
            intlValues: { account: data.filter_name }
          }
        }
      }
    });
  };

  render = () => {
    const { intl, emailFilter } = this.props;

    const columns = [
      {
        header: intl.formatMessage({ id: 'translate.wm.emailFilter.filter.name' }),
        accessor: 'filter_name'
      },
      {
        header: intl.formatMessage({ id: 'translate.generic.actions' }),
        accessor: 'id',
        render: this.renderContextMenu
      }
    ];

    return (
      <div>
        <PageHeader
          id={WmToolId.wmEmailFilters}
          instructions={intl.formatMessage({ id: 'translate.wm.emailFilter.info' })}
        />

        <Section>
          <Grid>
            <CreateBox>
              <CreateForm onSubmit={this.onCreateFormSubmit} />
            </CreateBox>

            <SGTable
              title={intl.formatMessage({ id: 'translate.wm.emailFilter.list.title' })}
              data={emailFilter}
              columns={columns}
              reordable={this.isTouchDevice() ? undefined : true}
              onReorder={(data) => this.isTouchDevice() === false && this.handleReorderedData(data)}
              resources={[
                { requestTypeName: customRequestTypes.EMAIL_FILTER_ORDER },
                { resourceName: API_RESOURCE.EMAIL_FILTER.resourceName, methods: ['GET'] }
              ]}
              noDataMessage="translate.wm.emailFilter.sg-table.no-data.message"
            />
          </Grid>
        </Section>

        {this.renderUpdateComponent()}
        {this.renderDeleteConformationDialogComponent()}
      </div>
    );
  };

  renderDeleteConformationDialogComponent = () => {
    const deletePayload = this.state.currentDeleteConformationDialogPayload;
    const entityName = deletePayload && deletePayload.entityName;

    return (
      <DeleteDialog
        title={this.props.intl.formatMessage({ id: 'translate.wm.emailFilter.delete.dialog.title' }, { entityName })}
        onSubmit={() => this.props.actions.deleteItem(deletePayload)}
      />
    );
  };

  renderUpdateComponent = () => {
    const { actions, closeSGDialog, intl, email } = this.props;
    const { currentUpdatePayload } = this.state;
    const entityName = currentUpdatePayload && currentUpdatePayload.filter_name;

    return (
      <SGDialogForm
        name={WM_REDUX_FORM.CHANGE_EMAIL_FILTERS_DIALOG}
        title={intl.formatMessage({ id: 'translate.wm.emailFilter.update.title' }, { filterName: entityName })}
        size="x-large"
        resources={[{ resourceName: API_RESOURCE.EMAIL_FILTER.resourceName, methods: ['PUT'] }]}
      >
        <UpdateFieldsForm
          initialValues={currentUpdatePayload}
          onSubmit={(data) => actions.updateItem(data, () => closeSGDialog(WM_REDUX_FORM.CHANGE_EMAIL_FILTERS_DIALOG))}
        />
      </SGDialogForm>
    );
  };

  renderContextMenu = (id, entity) => {
    const { intl, openSGDialog, emailFilter } = this.props;
    const isTouchDevice = this.isTouchDevice();

    const deletePayload: DeleteItemPayload = {
      itemId: id,
      entityName: entity.filter_name,
      _metaFields: { ...API_RESOURCE.EMAIL_FILTER },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.wm.emailFilter.deleted_msg',
            intlValues: { account: entity.filter_name }
          },
          error: {
            intlKey: 'translate.wm.emailFilter.failed_delete_msg',
            intlValues: { account: entity.filter_name }
          }
        }
      }
    };

    const updatePayload = {
      _metaFields: {
        ...API_RESOURCE.EMAIL_FILTER
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.wm.emailFilter.update_msg',
            intlValues: { account: entity.filter_name }
          },
          error: {
            intlKey: 'translate.wm.emailFilter.failed_update_msg',
            intlValues: { account: entity.filter_name }
          }
        }
      },
      ...entity
    };

    let buttonsToUse: any = [
      {
        vcsMethod: 'PUT',
        icon: 'edit',
        label: intl.formatMessage({ id: 'translate.generic.edit' }),
        e2eAttr: 'table-action-edit',
        visibleOnDesktop: !isTouchDevice,
        onClick: () =>
          this.setState({ currentUpdatePayload: updatePayload }, () =>
            openSGDialog(WM_REDUX_FORM.CHANGE_EMAIL_FILTERS_DIALOG)
          )
      },
      {
        vcsMethod: 'DELETE',
        icon: 'trash',
        label: intl.formatMessage({ id: 'translate.generic.delete' }),
        e2eAttr: 'table-action-delete',
        visibleOnDesktop: !isTouchDevice,
        onClick: () =>
          this.setState({ currentDeleteConformationDialogPayload: deletePayload }, () =>
            openSGDialog(DIALOGS.GENERIC_DELETE)
          )
      }
    ];

    if (isTouchDevice) {
      const currentFilterIndex = emailFilter.findIndex((e) => e.id === entity.id);

      if (currentFilterIndex !== 0) {
        buttonsToUse = buttonsToUse.concat({
          vcsMethod: 'PUT',
          icon: 'arrow-up',
          label: intl.formatMessage({ id: 'translate.wm.emailFilter.move.up' }),
          e2eAttr: 'table-action-move-up',
          onClick: () => {
            const data = moveEntity(emailFilter, currentFilterIndex, currentFilterIndex - 1);
            this.handleReorderedData(data);
          }
        });
      }

      if (currentFilterIndex !== emailFilter.length - 1) {
        buttonsToUse = buttonsToUse.concat({
          vcsMethod: 'PUT',
          icon: 'arrow-down',
          label: intl.formatMessage({ id: 'translate.wm.emailFilter.move.down' }),
          e2eAttr: 'table-action-move-down',
          onClick: () => {
            const data = moveEntity(emailFilter, currentFilterIndex, currentFilterIndex + 1);
            this.handleReorderedData(data);
          }
        });
      }
    }

    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.EMAIL_FILTER.resourceNameMetaApi}
        items={buttonsToUse}
      />
    );
  };
}

const mapStateToProps = (store) => ({
  email: getMainEmail(store),
  emailFilter: store.pageItems.emailFilter || [],
  environment: store.environment
});

export default indexWithCRUD(mapStateToProps, {
  openSGDialog,
  closeSGDialog,
  updateEmailFilterOrder
})(EmailFilterPage, API_RESOURCE.EMAIL_FILTER, API_RESOURCE.EMAIL);
