import { API_RESOURCE } from '../constants/api';
import { RootState } from '../../web/reducers';

export const hasCRUDPermission = (siteMetaApi: SiteMetaApi, pageEndpoint: string) => {
  if (siteMetaApi.loading) {
    return false;
  }
  const endpoint = siteMetaApi.endpoints && siteMetaApi.endpoints[pageEndpoint];

  return (
    endpoint &&
    endpoint.methods &&
    Object.keys(endpoint.methods).length > 0 &&
    (endpoint.methods.GET || endpoint.methods.POST || endpoint.methods.PUT || endpoint.methods.DELETE)
  );
};

export const getAvailableSlots = ({ siteMetaApi, pageItems }: Partial<RootState>): number => {
  if (siteMetaApi.loading) {
    return 0;
  }

  const items = pageItems[API_RESOURCE.BACKUP.resourceName] || [];
  const manualBackups = items.filter((backup) => backup.instant_backup).length;

  const { n_backups_allowed, max_user_backups } = siteMetaApi.features;
  return Math.min(max_user_backups - manualBackups, n_backups_allowed);
};

export const isSiteRecentlyCreated = ({ siteMetaApi }: Partial<RootState>, hoursBehind: number): boolean => {
  if (siteMetaApi.loading) {
    return true;
  }

  const { features } = siteMetaApi;
  const { site_created } = features;
  const siteCreation = site_created * 1000;

  const currentTime = new Date();
  const siteCreationTimeMargin = +currentTime.setHours(currentTime.getHours() - hoursBehind);

  return Boolean(siteCreationTimeMargin <= siteCreation);
};

export const isMemcacheEnabled = ({ siteMetaApi }: Partial<RootState>): boolean => {
  if (siteMetaApi.loading) {
    return false;
  }

  return Boolean(siteMetaApi.features && siteMetaApi.features.memcache_allowed);
};

export const isACLActionEnabled = ({ siteMetaApi }: Partial<RootState>, endpoint: string, role: RoleAction) => {
  if (siteMetaApi.loading) {
    return false;
  }

  return Boolean(
    siteMetaApi.endpoints &&
      siteMetaApi.endpoints[endpoint] &&
      siteMetaApi.endpoints[endpoint].meta &&
      Boolean(siteMetaApi.endpoints[endpoint].meta[role])
  );
};

export const getDnsServers = ({ siteMetaApi }: Partial<RootState>) =>
  (siteMetaApi && siteMetaApi.features && siteMetaApi.features.preferred_dns_servers) || [];

export const getScoId = ({ siteMetaApi }: Partial<RootState>) =>
  siteMetaApi && siteMetaApi.features && siteMetaApi.features.sco_id;

export const getSiteIp = ({ siteMetaApi }: Partial<RootState>) =>
  siteMetaApi &&
  siteMetaApi.features &&
  siteMetaApi.features.site_ip_data &&
  siteMetaApi.features.site_ip_data.length &&
  siteMetaApi.features.site_ip_data[0].ip_external;

export const findSiteNameServers = (sites) => {
  const { currentSiteID } = sites;
  const site = sites.items.find((s) => s.id === currentSiteID);

  if (site?.nameservers?.length > 0) {
    return site;
  }

  return null;
};

export const getNsInfo = <T extends RootState>({ siteMetaApi, sites }: T): NsInfo => {
  const siteNameServers = findSiteNameServers(sites);

  const nsInfo = {
    fullText: [],
    shortText: []
  };

  try {
    if (siteNameServers) {
      siteNameServers.nameservers.map((s) => {
        nsInfo.shortText.push(`${s.name}`);
        nsInfo.fullText.push(`${s.name} (${s.ip})`);
      });
    } else {
      siteMetaApi.features.site_dns_servers.map((s) => {
        nsInfo.shortText.push(`${s.name}`);
        nsInfo.fullText.push(`${s.name} (${s.addr})`);
      });
    }

    return nsInfo;
  } catch (e) {
    console.warn('Wrong NS info');
    return nsInfo;
  }
};

export const getCdnCacheType = (store): RootState => {
  return store && store.siteMetaApi && store.siteMetaApi.features && store.siteMetaApi.features.cdn_cache_type;
};

export const getCdnSuspendedCdn = (store): RootState => {
  return store && store.siteMetaApi && store.siteMetaApi.features && store.siteMetaApi.features.suspended_cdn;
};

export const getResourceUsage = (store): RootState => {
  return store && store.siteMetaApi && store.siteMetaApi.resource_usage;
};

export const getQuotaUsed = ({ siteMetaApi }: Partial<RootState>): SiteMetaApi['resource_usage'] =>
  siteMetaApi && siteMetaApi.resource_usage;

export const isApiDisabled = ({ siteMetaApi }: Partial<RootState>): boolean =>
  siteMetaApi && siteMetaApi.features && Boolean(siteMetaApi.features.api_disabled);

export const isUltrafastModDisabled = ({ siteMetaApi }: Partial<RootState>) =>
  siteMetaApi && siteMetaApi.features && !Boolean(siteMetaApi.features.mod_php_allowed);
