import * as React from 'react';
import { IntlShape } from 'react-intl';
import { Section } from '@siteground/styleguide';
import { updateItem } from '../../../core/actions/crud';
import { API_RESOURCE } from '../../../core/constants/api';
import { getPageInfo, WmToolId } from '../../../core/constants/route-info';
import indexWithCRUD from '../../../web/components/indexWithCRUD';
import { FirstLevelTitle } from '../../../web/components/titles';
import { WM_REDUX_FORM } from '../../core/constants/common';
import { getMainEmail } from '../../core/selectors/email';
import { CreateBox, CreateForm } from './create/';

type StoreProps = {
  email: any;
};

type DispatchProps = {
  updateItem: typeof updateItem;
};

type OwnProps = {
  intl: Intl;
};

type Props = StoreProps & DispatchProps & OwnProps;

class LoginCredentials extends React.PureComponent<Props> {
  render() {
    const { intl, email } = this.props;
    const { title } = getPageInfo(WmToolId.wmLoginCredentials);

    return (
      <div>
        <Section>
          <FirstLevelTitle>{intl.formatMessage({ id: title })}</FirstLevelTitle>
        </Section>
        <Section>
          <CreateBox>
            <CreateForm
              onSubmit={(data) => {
                const modifiedData = {
                  ...data,
                  id: email.id,
                  _meta: {
                    notification: {
                      type: 'form',
                      formName: WM_REDUX_FORM.CHANGE_EMAIL_PASSWORD,
                      success: {
                        intlKey: 'translate.wm.login.credentials.change.password.success.message'
                      },
                      error: {
                        intlKey: 'translate.wm.login.credentials.change.password.error.message'
                      }
                    }
                  }
                };

                delete modifiedData['confirm-password'];

                this.props.updateItem(modifiedData);
              }}
            />
          </CreateBox>
        </Section>
      </div>
    );
  }
}

export default indexWithCRUD(
  (store) => ({
    email: getMainEmail(store)
  }),
  { updateItem }
)(LoginCredentials, API_RESOURCE.EMAIL);
