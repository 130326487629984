import * as React from 'react';
import { reduxForm } from 'redux-form';
import { Column } from '@siteground/styleguide';

import { API_RESOURCE } from '../../../../core/constants/api';
import { SGForm, SGSubmitButton } from '../../../../web/components/sg-form';
import { withSpanelForm } from '../../../../web/components/sg-spanel-forms';
import { WM_REDUX_FORM } from '../../../core/constants/common';
import { comparisionOptions, matchOptions, subjectOptions, userActionOptions } from '../fields-options';
import { WMEmailFiltersFields } from '../wm-filters-fields';

type Props = {
  form: string;
  change: Function;
  formValues: any;
  validationUtils: any;
  sPanelFormSubmit: (formName) => null;
};

const formName = WM_REDUX_FORM.CREATE_EMAIL_FILTER_ITEM;

const Form = (props: Props) => {
  const { change, formValues, validationUtils, sPanelFormSubmit } = props;

  return (
    <SGForm onSubmit={sPanelFormSubmit(formName)} gridProps={{ xs: '12', sm: '12', lg: '12' }}>
      <WMEmailFiltersFields validationUtils={validationUtils} values={formValues(formName)} change={change} />

      <Column xsSpan="12">
        <SGSubmitButton formName={formName} />
      </Column>
    </SGForm>
  );
};

const EmailFilterForm = reduxForm({
  form: formName,
  initialValues: {
    match_cond: matchOptions[0].value,
    conditions: [
      {
        field: subjectOptions[0].value,
        expr: comparisionOptions[0].value
      }
    ],
    actions: [
      {
        action: userActionOptions[0].value
      }
    ],
    _metaFields: {
      ...API_RESOURCE.EMAIL_FILTER,
      formName
    }
  }
})(Form);

export default withSpanelForm(EmailFilterForm);
