import { call, put, select, takeLatest } from 'redux-saga/effects';
import { browserHistory } from 'react-router';

import * as fetchActions from '../../../core/actions/fetch';
import { SITE_REQUEST_AUTHORIZATION_FAILED } from '../../../core/constants/actions';
import { logoutFromWm } from '../actions/logout';
import { WmToolId } from '../../../core/constants/route-info';
import { WM_ROUTES } from '../../core/constants/WM_ROUTES';

export function* handleAvalonUnauthorized(action) {
  const { actionToRetry } = action.payload;

  yield put(fetchActions.httpRequestFailed(actionToRetry));

  yield put(logoutFromWm());
}

export default function* requestAuthorization() {
  yield takeLatest(SITE_REQUEST_AUTHORIZATION_FAILED, handleAvalonUnauthorized);
}
