import * as React from 'react';
import { useIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import { WM_REDUX_FORM } from '../../../core/constants/common';

import CreateBox from '../../../../web/components/create-box';

type Props = {
  children: any;
};

const { resourceName } = API_RESOURCE.EMAIL_AUTORESPONDER;
const formName = WM_REDUX_FORM.CREATE_AUTO_RESPONDER_WEB_MAIL;

const CreateContainer = ({ children }: Props) => {
  const intl = useIntl();
  return (
    <CreateBox
      hideTutorials
      title={intl.formatMessage({ id: 'translate.wm.mail.autoresponder-create-box.title' })}
      resourceName={resourceName}
      formName={formName}
      resources={[
        {
          resourceName: API_RESOURCE.EMAIL_AUTORESPONDER.resourceName,
          methods: ['POST']
        },
        {
          resourceName: API_RESOURCE.EMAIL.resourceName,
          methods: ['GET']
        }
      ]}
    >
      {children}
    </CreateBox>
  );
};

export default CreateContainer as typeof CreateContainer;
