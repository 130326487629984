import customRequestTypes from '../../../core/constants/custom-request-types';

export const enum ACTIONS {
  login = 'webmail/LOGIN'
}

export const login = ({ email, password, serverHostname, onComplete, onFailure }): NemoRequestAction => ({
  type: ACTIONS.login,
  payload: {
    email,
    password,
    serverHostname
  },
  requestTypeName: customRequestTypes.WEBMAIL_LOGIN,
  onComplete,
  onFailure
});
