import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Text, Title } from '@siteground/styleguide';
import { ToolId } from '../../../core/constants/route-info';
import { ToolWrapper } from '../../contexts';

type DefaultRenderProps = {
  children?: any;
  title: string;
  text?: string | JSX.Element;
};

const textProps = {};

const listProps = {};

const listItemProps = {
  icon: 'dot',
  iconSize: '10'
};

const toolDescription: { [tool: string]: { title: string; text: string } } = {
  [ToolId.appManager]: {
    title: 'translate.tips_and_tutorials.app_manager.title',
    text: 'translate.tips_and_tutorials.app_manager.content'
  },
  [ToolId.mysql]: {
    title: 'translate.tips_and_tutorials.mysql.title',
    text: 'translate.tips_and_tutorials.mysql.content'
  },
  [ToolId.pgsql]: {
    title: 'translate.tips_and_tutorials.pgsql.title',
    text: 'translate.tips_and_tutorials.pgsql.content'
  },
  [ToolId.backup]: {
    title: 'translate.tips_and_tutorials.backup.title',
    text: 'translate.tips_and_tutorials.backup.content'
  },
  [ToolId.ssl]: {
    title: 'translate.tips_and_tutorials.ssl.title',
    text: 'translate.tips_and_tutorials.ssl.content'
  },
  [ToolId.protectedUsers]: {
    title: 'translate.tips_and_tutorials.protected_users.title',
    text: 'translate.tips_and_tutorials.protected_users.content'
  },
  [ToolId.blockIPs]: {
    title: 'translate.tips_and_tutorials.block_ips.title',
    text: 'translate.tips_and_tutorials.block_ips.content'
  },
  [ToolId.sitescanner]: {
    title: 'translate.tips_and_tutorials.sitescanner.title',
    text: 'translate.tips_and_tutorials.sitescanner.content'
  },
  [ToolId.cloudflare]: {
    title: 'translate.tips_and_tutorials.cloudflare.title',
    text: 'translate.tips_and_tutorials.cloudflare.content'
  },
  [ToolId.cdn]: {
    title: 'translate.tips_and_tutorials.cdn.title',
    text: 'translate.tips_and_tutorials.cdn.content'
  },
  [ToolId.wpStaging]: {
    title: 'translate.tips_and_tutorials.wordpress_stage.title',
    text: 'translate.tips_and_tutorials.wordpress_stage.content'
  },

  [ToolId.wpAutoupdate]: {
    title: 'translate.tips_and_tutorials.wordpress_autoupdate.title',
    text: 'translate.tips_and_tutorials.wordpress_autoupdate.content'
  },

  [ToolId.parkedDomains]: {
    title: 'translate.tips_and_tutorials.parked_domains.title',
    text: 'translate.tips_and_tutorials.parked_domains.content'
  },

  [ToolId.subdomain]: {
    title: 'translate.tips_and_tutorials.subdomain.title',
    text: 'translate.tips_and_tutorials.subdomain.content'
  },

  [ToolId.redirect]: {
    title: 'translate.tips_and_tutorials.redirect.title',
    text: 'translate.tips_and_tutorials.redirect.content'
  },

  [ToolId.emailForward]: {
    title: 'translate.tips_and_tutorials.email_forward.title',
    text: 'translate.tips_and_tutorials.email_forward.content'
  },

  [ToolId.emailAutoresponder]: {
    title: 'translate.tips_and_tutorials.email_autoresponder.title',
    text: 'translate.tips_and_tutorials.email_autoresponder.content'
  },

  [ToolId.emailFilters]: {
    title: 'translate.tips_and_tutorials.email_filters.title',
    text: 'translate.tips_and_tutorials.email_filters.content'
  },

  [ToolId.git]: {
    title: 'translate.tips_and_tutorials.git.title',
    text: 'translate.tips_and_tutorials.git.content'
  },

  [ToolId.cron]: {
    title: 'translate.tips_and_tutorials.cron.title',
    text: 'translate.tips_and_tutorials.cron.content'
  },

  [ToolId.phpSettings]: {
    title: 'translate.tips_and_tutorials.php_settings.title',
    text: 'translate.tips_and_tutorials.php_settings.content'
  }
};

const DefaultRender = ({ title, text, children }: DefaultRenderProps) => (
  <React.Fragment>
    <Grid>
      <div>
        {title && (
          <Title density="compact" level="6">
            {title}
          </Title>
        )}

        {text && <Text>{text}</Text>}
      </div>

      {children}
    </Grid>
  </React.Fragment>
);

type RenderTutorialProps = {
  DefaultRender: (props: DefaultRenderProps) => any;
  textProps: any;
  listItemProps: any;
  listProps: any;
};

export interface TutorialsProps {
  intl: Intl;
  renderTutorials?: ({ DefaultRender, textProps, listItemProps }: RenderTutorialProps) => any;
}

class Tutorials extends React.Component<TutorialsProps, any> {
  renderTutorials = () => {
    return this.props.renderTutorials({
      DefaultRender,
      textProps,
      listProps,
      listItemProps
    });
  };

  renderTipsAndTutorials = ({ toolId, pageId }) => {
    const { intl } = this.props;
    const id = pageId || toolId;
    const description = toolDescription[id];

    if (description) {
      return (
        <DefaultRender
          title={intl.formatMessage({ id: description.title })}
          text={intl.formatMessage({ id: description.text })}
        />
      );
    }

    return this.renderTutorials();
  };

  render() {
    return (
      <ToolWrapper.Consumer>
        {({ toolId, pageId }) => this.renderTipsAndTutorials({ toolId, pageId })}
      </ToolWrapper.Consumer>
    );
  }
}

export default injectIntl(Tutorials);
