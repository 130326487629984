import { call, fork, put } from 'redux-saga/effects';
import * as fetchActions from '../actions/fetch';
import { siteRequestAuthorizationFailed } from '../actions/session';
import { fetchPendingTasksRequested, requestStartedBackgroundTask, subscribeForTaskCompletion } from '../actions/tasks';
import { fetchSiteMetaApi } from '../actions/site-meta-api';
import { setMaintenancePage } from '../actions/maintenance';
import { getTasksIds } from '../selectors/pending-tasks';
import * as NotificationActions from '../actions/notifications';
import getStore from '../store';

const INITIAL_RETRY_COUNT = 7;

function handleAvalonApiRequest(saga) {
  const store = getStore();

  /* Counting request 401 failures (SPANEL-3944),
    counter is reset when the request succeeds or fails with different status than 401 */
  let retryRefreshTokenCount = INITIAL_RETRY_COUNT;

  return function* callSaga(action) {
    const apiCallHandler = yield fork(function* () {
      try {
        yield put(fetchActions.httpRequestStarted(action));
        const response = yield call(saga, action);

        // fetch pendingTasks from anyApi
        const allPendingTasksIds = getTasksIds(response);

        if (allPendingTasksIds.length > 0) {
          yield put(fetchPendingTasksRequested(allPendingTasksIds));
        }

        yield put(fetchActions.httpRequestSucceeded(action, response));

        retryRefreshTokenCount = INITIAL_RETRY_COUNT;
      } catch (exception) {
        if (exception.status !== 401) {
          retryRefreshTokenCount = INITIAL_RETRY_COUNT;
        }

        if (exception.status === 202 && exception.data && exception.data.task_id) {
          yield put(fetchPendingTasksRequested([exception.data.task_id]));

          yield put(requestStartedBackgroundTask(action));

          yield put(
            subscribeForTaskCompletion(
              exception.data.task_id,
              (response, task?: Task) => {
                store.dispatch(fetchActions.httpRequestSucceeded(action, response, task));
              },
              (message, task?: Task) => store.dispatch(fetchActions.httpRequestFailed(action, { message }, task))
            )
          );

          return;
        } else if (exception.status === 401 && retryRefreshTokenCount > 0) {
          retryRefreshTokenCount = retryRefreshTokenCount - 1;
          yield put(siteRequestAuthorizationFailed(action));
          return;
        } else if (exception.status === 401 && retryRefreshTokenCount <= 0) {
          yield put(
            NotificationActions.createNotification({
              type: 'generic',
              state: 'error',
              error: {
                intlKey: 'translate.generic.notification.error.authorizing'
              }
            })
          );
        } else if (exception.status === 403) {
          // user hit quota limit, re-fetch site meta api
          yield put(fetchSiteMetaApi({ requestTypeName: 'RE_FETCH_SITE_META_API_WITHOUT_LOADER' }));
        } else if (exception.status === 533) {
          yield put(setMaintenancePage(action.payload.endpoint));
        }

        // executed if exception is not for background task or re-auth
        yield put(fetchActions.httpRequestFailed(action, exception));
      }
    });
  };
}

export default handleAvalonApiRequest;
