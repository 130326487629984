import punycode from 'punycode';
import { FILE_MANGER_API_FILE } from '../../web/file-manager/core/constants/actions';
import { formatRequestBodyAvalon, jsonResponse, shouldTransformValue } from './utils';
import { getCurrentSite, getCurrentSiteId, getSiteToken } from '../selectors';

type siteApi = () => Promise<Response>;
type siteApiParams = {
  endpoint: string;
  method: string;
  body?: any;
  state: any;
  urlParams?: any;
  disableBodyStringify?: boolean;
};

export const CustomError = function (object) {
  this.status = object.status;
  this.title = object.title;
  this.message = object.message;
  this.err_fields = object.err_fields || {};

  // Nemo errors
  this.validation = object.validation || {};
};

export const NewTasks = function (object) {
  this.status = object.status;
  this.data = object.data;
};

export function siteApi({
  endpoint,
  method = 'GET',
  body = {},
  state,
  urlParams = {},
  disableBodyStringify = false
}: siteApiParams): siteApi {
  const endpointName: string = endpoint.replace('/', '');
  const bodyToBeSend = disableBodyStringify ? body : formatRequestBodyAvalon(body, endpointName);

  const siteId: string = getCurrentSiteId(state);
  const siteToken: string = getSiteToken(state, siteId);
  const site: SiteData = getCurrentSite(state);

  const getParams: string = Object.keys(urlParams)
    .map((key) => {
      let paramValue = urlParams[key];

      if (shouldTransformValue({ key, endpointName })) {
        paramValue = punycode.toASCII(paramValue);
      }

      return `${key}=${encodeURIComponent(paramValue)}&`;
    })
    .join('');

  if (!siteToken) {
    throw new CustomError({
      status: 0,
      title: 'Missing SiteToken',
      message: 'Missing SiteToken'
    });
  }

  let newEndpoint = `https://${site.api_url}/api-sgcp/v00${endpoint}?${getParams}_site_token=${siteToken}`;

  // Bug when changing pages
  if (method === 'POST' && (window as any).timeout) {
    newEndpoint = `${newEndpoint}&_timeout=1&_wait_time=${(window as any).timeout.toString()}`;
  }

  return () => {
    let args: any = {
      headers: {
        Accept: 'application/json'
      },
      method
    };

    if (method !== 'GET') {
      args = {
        ...args,
        body: bodyToBeSend
      };
    }

    return fetch(newEndpoint, args)
      .catch((error) => {
        throw Error('An error occurred. Please try again later');
      })
      .then((response) => {
        if (endpoint === FILE_MANGER_API_FILE && response.status === 200) {
          return response.text();
        }

        return jsonResponse(response, endpointName);
      })
      .then((data) => {
        if (typeof data === 'string') {
          return data;
        }

        const json = data;

        switch (json.status) {
          case 200:
            return json;
          case 201:
            return json;
          case 202:
            throw new NewTasks(json);
          case 401:
            throw new CustomError(json);
          case 533:
            throw new CustomError(json);
          default:
            throw new CustomError(json);
        }
      });
  };
}
