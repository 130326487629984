import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form';
import { Column, Icon, Text } from '@siteground/styleguide';

import FormDatePicker from '../../../web/components/form-date-picker';
import FormInput from '../../../web/components/form-input';
import FormTypeEditor from '../../../web/components/form-type-editor';

const HOUR_IN_SECONDS = 3600;
const MAX_FUTURE_DATE_YEARS = 10;

const isAutoresponderDateValid = (utcDate: number): boolean => {
  const today = new Date() as any;

  const currentTime = {
    year: today.getFullYear(),
    month: today.getMonth(),
    day: today.getDate()
  };

  const maxDate = today.setYear(currentTime.year + MAX_FUTURE_DATE_YEARS);
  const utcMaxDate = +maxDate / 1000;

  return Boolean(utcDate <= utcMaxDate);
};

class FilterField extends React.Component<any, any> {
  convertHoursToSeconds = (hour) => {
    const intValue = parseInt(hour, 10);

    if (isNaN(intValue)) {
      return hour;
    }

    return intValue / 60 / 60;
  };

  convertSecondsToHours = (hour) => {
    const secondsToHours = (parseInt(hour, 10) * 60 * 60).toString();

    return secondsToHours !== 'NaN' ? secondsToHours : '0';
  };

  modifyEndDateInput = (value, previousValue, allValues) => {
    const { changeFieldValue } = this.props;

    if (!previousValue || !allValues.ts_stop || allValues.ts_stop - HOUR_IN_SECONDS < value) {
      changeFieldValue('ts_stop', parseInt(value, 10) + HOUR_IN_SECONDS);
    }

    return value;
  };

  normalizeEndDate = (value, previousValue, allValues) => {
    if (previousValue === undefined || allValues.ts_stop === undefined) {
      return value;
    }

    if (value - HOUR_IN_SECONDS < allValues.ts_start) {
      return parseInt(allValues.ts_start, 10) + HOUR_IN_SECONDS;
    }

    return value;
  };

  render() {
    const { intl, values, validationUtils, email, theme, changeFieldValue } = this.props;
    const startDate = values && values.ts_start;

    return (
      <React.Fragment>
        <Column smSpan="6">
          <Field
            name="name"
            disabled
            type="text"
            value={email}
            valueTemplate={() => email}
            label={intl.formatMessage({ id: 'translate.wm.email-autoresponder.for.email' })}
            component={FormInput}
          />
        </Column>

        <Column smSpan="6">
          <Field
            name="email_name"
            type="text"
            label={intl.formatMessage({ id: 'translate.wm.email-autoresponder.from.name' })}
            placeholder={intl.formatMessage({ id: 'translate.generic.name.placeholder' })}
            component={FormInput}
          />
        </Column>

        <Column smSpan="12">
          <Field
            name="email_subject"
            type="text"
            label={intl.formatMessage({ id: 'translate.wm.email-autoresponder.subject' })}
            validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
            component={FormInput}
          />
        </Column>

        <Column smSpan="12">
          <Field
            name="email_body"
            type="text"
            values={values}
            theme={theme}
            changeFieldValue={changeFieldValue}
            label={intl.formatMessage({ id: 'translate.generic.body' })}
            validate={[validationUtils.required, validationUtils.validationWithMetaApi]}
            component={FormTypeEditor}
          />
        </Column>

        <Column smSpan="6" data-e2e="start-date-time">
          <Field
            name="ts_start"
            type="text"
            labelDate={intl.formatMessage({ id: 'translate.wm.email-autoresponder.start.date' })}
            labelTime={intl.formatMessage({ id: 'translate.wm.email-autoresponder.start.time' })}
            shouldDisableDate={(utcDate) => {
              const today = new Date();
              const utcToday = Math.floor(today.setHours(0, 0, 0, 0) / 1000);

              return Boolean(utcToday > utcDate || !isAutoresponderDateValid(utcDate));
            }}
            normalize={this.modifyEndDateInput}
            component={FormDatePicker}
          />
        </Column>

        <Column smSpan="6" data-e2e="end-date-time">
          <Field
            name="ts_stop"
            type="text"
            labelDate={intl.formatMessage({ id: 'translate.wm.email-autoresponder.end.date' })}
            labelTime={intl.formatMessage({ id: 'translate.wm.email-autoresponder.end.time' })}
            shouldDisableDate={(utcDate) => Boolean(startDate > utcDate || !isAutoresponderDateValid(utcDate))}
            disabled={!startDate}
            normalize={this.normalizeEndDate}
            component={FormDatePicker}
          />
        </Column>

        <Column smSpan="12" className="email-autoresponders-match-conditions">
          <Text style={{ whiteSpace: 'nowrap' }}>{intl.formatMessage({ id: 'translate.generic.wait' })}</Text>

          <Field
            name="wait_time"
            className="email-autoresponders-match-conditions__wait-tile"
            valueTemplate={this.convertHoursToSeconds}
            onChangeTemplate={(event) => this.convertSecondsToHours(event.target.value)}
            component={FormInput}
          />

          <Text style={{ textAlign: 'left' /* the align property adds align-self - breaking the dialog on phone*/ }}>
            {intl.formatMessage({ id: 'translate.wm.email-autoresponder.create.wait.hours.text' })}
            &nbsp;
            <Icon
              name="information"
              color="light"
              size="12"
              tooltip={intl.formatMessage({ id: 'translate.wm.email-autoresponder.create.wait.hours.tooltip' })}
              tooltipSize="medium"
            />
          </Text>
        </Column>
      </React.Fragment>
    );
  }
}

export default injectIntl(FilterField);
