import { call, put, takeEvery } from 'redux-saga/effects';
import punycode from 'punycode';
import * as fetchActions from '../../../core/actions/fetch';
import * as i18nActions from '../../../core/actions/i18n';
import { saveSiteToken } from '../../../core/actions/session';
import { fetchSiteMetaApi } from '../../../core/actions/site-meta-api';
import { storeSites } from '../../../core/actions/sites';
import { loadBackgroundTasks } from '../../../core/actions/tasks';
import { clientApi } from '../../../core/api/client';
import { transformEmail } from '../../../core/api/utils';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { parseJwt } from '../../../core/utils/session';
import { ACTIONS } from '../actions/login';

function* getWebappToken({ email, password, serverHostname }) {
  return yield call(
    clientApi('/auth/webapp-token', 'POST', {
      email,
      password,
      server_hostname: serverHostname
    })
  );
}

export function* onUserLogged(data) {
  const { web_app_token } = data;
  const { api_url, site_id } = parseJwt(web_app_token);

  yield put(storeSites([{ id: site_id, domain: site_id, api_url }], site_id));

  yield put(
    saveSiteToken(site_id, {
      data: {
        site_token: web_app_token
      }
    })
  );

  yield put(fetchSiteMetaApi({ requestTypeName: customRequestTypes.WEBMAIL_FETCH_SITE_META_API }));
  yield put(i18nActions.loadTranslations({ fileName: 'en' }));
  yield put(loadBackgroundTasks());
}

function* login(action) {
  const { payload } = action;
  const { email, password, serverHostname } = payload;

  yield put(fetchActions.httpRequestStarted(action));

  try {
    const response = yield call(getWebappToken, {
      email: transformEmail(email, punycode.toASCII),
      password,
      serverHostname
    });

    yield call(onUserLogged, response.data);

    yield put(fetchActions.httpRequestSucceeded(action, response));
  } catch (e) {
    yield put(fetchActions.httpRequestFailed(action, e));
  }
}

export default function* () {
  yield takeEvery(ACTIONS.login, login);
}
