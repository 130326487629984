import { put, takeEvery } from 'redux-saga/effects';
import { redirectToLogin } from '../utils/redirect-to-login';
import { LOGOUT_FROM_WEBAPP } from '../../../core/constants/actions';
import * as fetchActions from '../../../core/actions/fetch';
import { getRcmailIframe } from '../utils/common';

export default function* logout() {
  yield takeEvery(LOGOUT_FROM_WEBAPP, function* (action) {
    yield put(fetchActions.httpRequestStarted(action));

    const iframe: any = getRcmailIframe();

    if (iframe && iframe.rcmail) {
      const token = iframe.rcmail.env.request_token;
      const location = iframe.location.href;
      const iframeURL = new URL(location);

      fetch(`${iframeURL.origin}${iframeURL.pathname}?_task=logout&_token=${token}`).catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
    }

    redirectToLogin();
  });
}
