import * as React from 'react';
import { reduxForm } from 'redux-form';
import { Grid } from '@siteground/styleguide';
import { withSpanelForm } from '../../../../web/components/sg-form-util/with-spanel-form';
import { WM_REDUX_FORM } from '../../../core/constants/common';
import { WMEmailFiltersFields } from '../wm-filters-fields';

type Props = {
  change: Function;
  getFormValues: (formName) => any;
  validationUtils: ValidationUtils;
};

const formName = WM_REDUX_FORM.CHANGE_EMAIL_FILTERS_DIALOG;

const ChangeFields = ({ change, getFormValues, validationUtils }: Props) => (
  <Grid>
    <WMEmailFiltersFields change={change} values={getFormValues(formName)} validationUtils={validationUtils} />
  </Grid>
);

export default withSpanelForm(reduxForm({ form: formName })(ChangeFields));
