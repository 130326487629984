import { handleActions } from 'redux-actions';
import { ACTIONS } from '../actions/page';

const initialState: any = {
  task: 'mail',
  command: null
};

const reducerMap: any = {
  [ACTIONS.saveNavigationCommand]: (state, { payload }) => {
    const { task, command } = payload;
    return {
      ...state,
      task,
      command
    };
  }
};

export default handleActions(reducerMap, initialState);
