import createItem from '../core/sagas/crud/create-item';
import deleteItem from '../core/sagas/crud/delete-item';
import fetchItem from '../core/sagas/crud/fetch-item';
import fetchItems from '../core/sagas/crud/fetch-items';
import updateItem from '../core/sagas/crud/update-item';
import formSubmission from '../core/sagas/form-submission';
import genericRequests from '../core/sagas/generic-requests';
import nemoStore from '../core/sagas/nemo-store';
import notifications from '../core/sagas/notifications';
import openNewTabSaga from '../core/sagas/open-new-tab';
import requestAutoconfigureData from '../core/sagas/pages/email-accounts';
import emailFilters from '../core/sagas/pages/email-filters';
import pingBackend from '../core/sagas/ping-backend';
import requestsCompletionHandling from '../core/sagas/requests-completion';
import sgDialog from '../core/sagas/sg-dialog';
import siteMetaApi from '../core/sagas/site-meta-api';
import spanelForms from '../core/sagas/spanel-forms';
import tasks from '../core/sagas/tasks';
import translations from '../core/sagas/translations';

import login from './core/sagas/login';
import pageLoad from './core/sagas/page-load';
import requestAuthorization from './core/sagas/request-authorization';
import logout from './core/sagas/logout';

export default function* sagas() {
  yield [
    createItem(),
    updateItem(),
    deleteItem(),
    fetchItem(),
    fetchItems(),
    tasks(),
    spanelForms(),
    formSubmission(),
    nemoStore(),
    sgDialog(),
    genericRequests(),
    notifications(),
    requestsCompletionHandling(),
    siteMetaApi(),
    pingBackend(),
    openNewTabSaga(),
    translations(),

    // Pages specific
    emailFilters(),
    requestAutoconfigureData(),

    // Webmail specific sagas
    pageLoad(),
    login(),
    requestAuthorization(),
    logout()
  ];
}
