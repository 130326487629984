import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@siteground/styleguide';
import { WM_REDUX_FORM } from '../../core/constants/common';
import { withSpanelForm } from '../../../web/components/sg-form-util/with-spanel-form';
import AutoResponderFields from './autoresponder-fields';

type Props = {
  change: Function;
  getFormValues: (formName) => any;
  onSubmit: Function;
  email: string;
  selectedDomain: any;
  validationUtils: ValidationUtils;
};

const formName = WM_REDUX_FORM.CHANGE_EMAIL_AUTORESPONDER_DIALOG_WEB_MAIL;

const UpdateFieldsForm = ({ change, getFormValues, email, selectedDomain, validationUtils }: Props) => (
  <Grid>
    <AutoResponderFields
      email={email}
      selectedDomain={selectedDomain}
      values={getFormValues(formName)}
      changeFieldValue={change}
      validationUtils={validationUtils}
    />
  </Grid>
);

export default withSpanelForm(reduxForm({ form: formName })(UpdateFieldsForm));
