import * as React from 'react';
import { TitleFour, TitleProps } from '@siteground/styleguide';

const SecondLevelTitle = ({ children, style, id }: Partial<TitleProps>) => {
  return (
    <TitleFour id={id} tag="h2" style={style}>
      {children}
    </TitleFour>
  );
};

export default SecondLevelTitle;
