import { RouteActions, routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import environment from '../core/reducers/environment';
import httpRequests, { HttpRequests } from '../core/reducers/http-requests';
import i18n from '../core/reducers/i18n';
import indexWithCrud, { IndexWithCrud } from '../core/reducers/index-with-crud';
import lastActions, { LastActions } from '../core/reducers/last-actions';
import siteMetaApi from '../core/reducers/meta-api';
import nemoStore, { NemoStore } from '../core/reducers/nemo-store/nemo-store';
import notifications, { NotificationsState } from '../core/reducers/notifications';
import pageItems from '../core/reducers/page-items';
import ping, { PingBackend } from '../core/reducers/ping-backend';
import session from '../core/reducers/session';
import sgDialog from '../core/reducers/sg-dialog';
import sgTable from '../core/reducers/sg-table';
import sites from '../core/reducers/sites';
import tasks from '../core/reducers/tasks';
import navigation from './core/reducers';

export interface RootState {
  environment: any;
  siteMetaApi: SiteMetaApi;
  httpRequest: any;
  routing: RouteActions;
  session: any;
  sites: Sites;
  form: any;
  pageItems: any;
  notifications: NotificationsState;
  i18n: {
    showMessages: boolean;
    locale: string;
    messages: {};
  };
  tasks: Task[];
  tasksLoader: any;
  dialog: any;
  table: any;
  nemoStore: NemoStore;
  httpRequests: HttpRequests;
  indexWithCrud: IndexWithCrud;

  ping: PingBackend;
  lastActions: LastActions;
}

export default combineReducers<RootState>({
  environment,
  siteMetaApi,
  routing,
  sites,
  session,
  pageItems,
  notifications,
  form,
  i18n,
  tasks,
  nemoStore,
  dialog: sgDialog,
  table: sgTable,
  httpRequests,
  indexWithCrud,
  navigation,

  ping,
  lastActions
});
