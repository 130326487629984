import { TitleProps } from '@siteground/styleguide';
import customRequestTypes from './custom-request-types';

export const NOTIFICATION_DELAY = 7000;
export const FORM_NOTIFICATION_DELAY = 14000;
export const MAIN_DOMAIN_ID = 1;
export const BYTES_IN_MB = 1048576;
export const BYTES_IN_KB = 1024;

export const PAGE_LOAD_REQUESTS = [
  {
    requestTypeName: customRequestTypes.PAGE_LOAD
  },
  {
    requestTypeName: customRequestTypes.CHANGE_CURRENT_SITE
  },
  {
    requestTypeName: customRequestTypes.REDIRECT_FROM_REMOTE
  },
  {
    requestTypeName: customRequestTypes.FETCH_SITE_META_API
  },
  {
    requestTypeName: customRequestTypes.NAVIGATING_TO_UA
  },
  {
    requestTypeName: customRequestTypes.LOGOUT
  },
  {
    requestTypeName: customRequestTypes.LOAD_TRANSLATIONS
  },
  {
    requestTypeName: customRequestTypes.RETRY_PAGE_LOAD
  }
];

// REDUX FORM NAMES
export const REDUX_FORM = {
  GENERIC_PASSWORD: 'GENERIC_PASSWORD',
  GENERIC_QUOTA: 'GENERIC_QUOTA',
  GENERIC_HOME: 'GENERIC_HOME',
  GENERIC_ACCOUNT_NAME: 'GENERIC_ACCOUNT_NAME',

  EMAIL_AUTH_MANAGE_APPROVED_HOSTS: 'EMAIL_AUTH_MANAGE_APPROVED_HOSTS',
  EMAIL_AUTH_MANAGE_APPROVED_MX_RECORDS: 'EMAIL_AUTH_MANAGE_APPROVED_MX_RECORDS',
  EMAIL_AUTH_MANAGE_APPROVED_IP_BLOCKS: 'EMAIL_AUTH_MANAGE_APPROVED_IP_BLOCKS',
  EMAIL_AUTH_MANAGE_APPROVED_INCLUDE_LIST: 'EMAIL_AUTH_MANAGE_APPROVED_INCLUDE_LIST',

  BACKUP_RESTORE_FILES_DIALOG: 'BACKUP_RESTORE_FILES_DIALOG',
  BACKUP_RESTORE_DATABASE_DIALOG: 'BACKUP_RESTORE_DATABASE_DIALOG',
  BACKUP_RESTORE_EMAILS_DIALOG: 'BACKUP_RESTORE_EMAILS_DIALOG',
  SSL_CERTIFICATE_DOMAINS_DIALOG: 'SSL_CERTIFICATE_DOMAINS_DIALOG',
  SSL_CERTIFICATE_DIALOG: 'SSL_CERTIFICATE_DIALOG',
  SSL_RENEW_PREMIUM_DIALOG: 'SSL_RENEW_PREMIUM_DIALOG',
  SSL_RENEW_LE_DIALOG: 'SSL_RENEW_LE_DIALOG',
  WP_AUTOUPDATE_ADD_APP_DIALOG: 'WP_AUTOUPDATE_ADD_APP_DIALOG',
  WP_AUTOUPDATE_LIST_DIALOG: 'WP_AUTOUPDATE_LIST_DIALOG',
  CLOUDFLARE_ADD_IP_RANGE_DIALOG: 'CLOUDFLARE_ADD_IP_RANGE_DIALOG',
  CLOUDFLARE_UPDATE_IP_RANGE_DIALOG: 'CLOUDFLARE_UPDATE_IP_RANGE_DIALOG',
  CLOUDFLARE_CONNECT_ACCOUNT: 'CLOUDFLARE_CONNECT_ACCOUNT',
  EMAIL_FOLDER_DISCARD_DIALOG: 'EMAIL_FOLDER_DISCARD_DIALOG',
  EMAIL_FOLDER_FORWARD_DIALOG: 'EMAIL_FOLDER_FORWARD_DIALOG',
  SSH_CHANGE_KEY_NAME: 'SSH_CHANGE_KEY_NAME',
  SSH_CHANGE_IP: 'SSH_CHANGE_IP',

  CREATE_EMAIL_FILTER_ITEM: 'CREATE_EMAIL_FILTER_ITEM',
  CREATE_EMAIL_AUTORESPONDER: 'CREATE_EMAIL_AUTORESPONDER',
  CHANGE_EMAIL_FILTERS_DIALOG: 'CHANGE_EMAIL_FILTERS_DIALOG',
  CHANGE_DOMAIN_REDIRECT_DIALOG: 'CHANGE_DOMAIN_REDIRECT_DIALOG',
  CHANGE_LOCATION_IP_BLOCK_DIALOG: 'CHANGE_LOCATION_IP_BLOCK_DIALOG',
  CHANGE_DNS_DIALOG: 'CHANGE_DNS_DIALOG',
  ADD_EXISTING_APP: 'ADD_EXISTING_APP',
  CHANGE_WP_MANAGE_PASSWORD_DIALOG: 'CHANGE_WP_MANAGE_PASSWORD_DIALOG',
  CHANGE_WP_MANAGE_FIX_PERMISSIONS_DIALOG: 'CHANGE_WP_MANAGE_FIX_PERMISSIONS_DIALOG',
  CHANGE_WP_MANAGE_MOVE_DIALOG: 'CHANGE_WP_MANAGE_MOVE_DIALOG',
  DELETE_INSTALLED_WP_APP: 'DELETE_INSTALLED_WP_APP',
  UPDATE_EMAIL_FILTER_ITEM: 'UPDATE_EMAIL_FILTER_ITEM',
  UPDATE_EMAIL_FILTER_ITEM_DIALOG: 'UPDATE_EMAIL_FILTER_ITEM_DIALOG',

  CHANGE_NAME_DIALOG: 'CHANGE_NAME_DIALOG',
  CHANGE_HOME_DIALOG: 'CHANGE_HOME_DIALOG',
  CHANGE_EMAIL_AUTORESPONDER_DIALOG: 'CHANGE_EMAIL_AUTORESPONDER_DIALOG',
  CHANGE_CRON_DIALOG: 'CHANGE_CRON_DIALOG',
  CHANGE_CRON_NOTIFICATION: 'CHANGE_CRON_NOTIFICATION',
  CHANGE_WP_APP_STAGING_NAME: 'CHANGE_WP_APP_STAGING_NAME',
  REPLICATE_WP_APP_STAGING: 'REPLICATE_WP_APP_STAGING',
  CHANGE_PHP_VERSION: 'CHANGE_PHP_VERSION',
  CHANGE_PHP_INI_SETTING: 'CHANGE_PHP_INI_SETTING',
  CHANGE_PHP_EXTENSION_SETTING: 'CHANGE_PHP_EXTENSION_SETTING',
  CHANGE_PROTECTED_USERS_DIALOG: 'CHANGE_PROTECTED_USERS_DIALOG',
  CHANGE_PROTECTED_URLS_DIALOG: 'CHANGE_PROTECTED_URLS_DIALOG',
  ADD_USER_TO_DB: 'ADD_USER_TO_DB',
  ADD_DB_TO_USER: 'ADD_DB_TO_USER',
  EDIT_DB_USER: 'EDIT_DB_USER',
  SELECT_DB_DUMP: 'SELECT_DB_DUMP',
  EDIT_DB_LABEL: 'EDIT_DB_LABEL',
  EDIT_DB_ACCESS: 'EDIT_DB_ACCESS',

  SELECT_FOLDER_DIALOG: 'SELECT_FOLDER_DIALOG',
  CHOOSE_MAIL_CLIENT_FORM: 'CHOOSE_MAIL_CLIENT_FORM',

  SG_SITESCANNER_QUARANTINE_DIALOG: 'SG_SITESCANNER_QUARANTINE_DIALOG',

  CREATE_GIT: 'CREATE_GIT',
  CREATE_CLOUDFLARE_ACCOUNT: 'CREATE_CLOUDFLARE_ACCOUNT',
  CREATE_CLOUDFLARE_ZONE: 'CREATE_CLOUDFLARE_ZONE',
  CREATE_CDN: 'CREATE_CDN',
  CREATE_WP_AUTOUPDATE: 'CREATE_WP_AUTOUPDATE',
  CREATE_WP_MANAGE: 'CREATE_WP_MANAGE',
  CREATE_WP_TRANSFER: 'CREATE_WP_TRANSFER',
  CREATE_BACKUP: 'CREATE_BACKUP',
  CREATE_DOMAIN_ALIAS: 'CREATE_DOMAIN_ALIAS',
  CREATE_EMAIL: 'CREATE_EMAIL',
  CREATE_EMAIL_DISCARD: 'CREATE_EMAIL_DISCARD',
  CREATE_EMAIL_FORWARD: 'CREATE_EMAIL_FORWARD',
  CREATE_FTP: 'CREATE_FTP',
  CREATE_SUB_DOMAIN: 'CREATE_SUB_DOMAIN',
  CREATE_REDIRECT: 'CREATE_REDIRECT',
  CREATE_MYSQL_ACCESS: 'CREATE_MYSQL_ACCESS',
  CREATE_MYSQL_USERS: 'CREATE_MYSQL_USERS',
  CREATE_MYSQL_DATABASE: 'CREATE_MYSQL_DATABASE',
  CREATE_PGSQL_ACCESS: 'CREATE_PGSQL_ACCESS',
  CREATE_PGSQL_USERS: 'CREATE_PGSQL_USERS',
  CREATE_PGSQL_DATABASE: 'CREATE_PGSQL_DATABASE',
  CREATE_LOCATION_IP_BLOCK: 'CREATE_LOCATION_IP_BLOCK',

  CREATE_DNS_A: 'CREATE_DNS_A',
  CREATE_DNS_AAAA: 'CREATE_DNS_AAAA',
  CREATE_DNS_CNAME: 'CREATE_DNS_CNAME',
  CREATE_DNS_MX: 'CREATE_DNS_MX',
  CREATE_DNS_MX_GOOGLE: 'CREATE_DNS_MX_GOOGLE',
  CREATE_DNS_MX_SYSTEM_DEFAULT: 'CREATE_DNS_MX_SYSTEM_DEFAULT',
  CREATE_DNS_SRV: 'CREATE_DNS_SRV',
  CREATE_DNS_TXT: 'CREATE_DNS_TXT',

  CREATE_INSTALL_SSL: 'CREATE_SSL_LE',
  CREATE_SSL_PREMIUM: 'CREATE_SSL_PREMIUM',
  CREATE_SSL_IMPORT: 'CREATE_SSL_IMPORT',
  CREATE_ITEM_LOCATION_PROTECT: 'CREATE_ITEM_LOCATION_PROTECT',
  CREATE_ITEM_LOCATION_USER: 'CREATE_ITEM_LOCATION_USER',
  CREATE_ITEM_LOCATION_USER_DIALOG: 'CREATE_ITEM_LOCATION_USER_DIALOG',
  CREATE_SSH: 'CREATE_SSH',
  CREATE_SSH_KEY: 'CREATE_SSH_KEY',
  CREATE_STAGING: 'CREATE_STAGING',
  CREATE_ITEM_CRON_NOTIFY: 'CREATE_ITEM_CRON_NOTIFY',
  CREATE_ITEM_CRON: 'CREATE_ITEM_CRON',
  CREATE_APP_INSTALL: 'CREATE_APP_INSTALL',

  WP_REPLACE: 'WP_REPLACE',

  PHP_SELECT_DOMAIN_LIVE_SITES: 'PHP_SELECT_DOMAIN_LIVE_SITES',
  PHP_SELECT_DOMAIN_STAGING_SITES: 'PHP_SELECT_DOMAIN_STAGING_SITES',

  FILE_MANAGER_ARCHIVE: 'FILE_MANAGER_ARCHIVE',
  FILE_MANAGER_CREATE_DIR: 'FILE_MANAGER_CREATE_DIR',
  FILE_MANAGER_CREATE_FILE: 'FILE_MANAGER_CREATE_FILE',
  FILE_MANAGER_MOVE_ENTITY: 'FILE_MANAGER_MOVE_ENTITY',
  FILE_MANAGER_RENAME_ENTITY: 'FILE_MANAGER_RENAME_ENTITY',
  FILE_MANAGER_PERMISSIONS: 'FILE_MANAGER_PERMISSIONS',
  FILE_MANAGER_SEARCH: 'FILE_MANAGER_SEARCH',

  CHANGE_PROFILE_PASSWORD_DIALOG: 'CHANGE_PROFILE_PASSWORD_DIALOG',

  UKRAINE: 'UKRAINE'
};

export const DIALOGS = {
  GENERIC_DELETE: 'GENERIC_DELETE',
  SELECT_DIRECTORY: 'SELECT_DIRECTORY',
  SELECT_DIRECTORY_ADD_APP: 'SELECT_DIRECTORY_ADD_APP',
  SELECT_DIRECTORY_INSTALL_PATH: 'SELECT_DIRECTORY_INSTALL_PATH',
  SELECT_EMAIL: 'SELECT_EMAIL',
  SSL_PREMIUM_DELETE: 'SSL_PREMIUM_DELETE',
  SSL_UNABLE_TO_RENEW: 'SSL_UNABLE_TO_RENEW',
  EMAIL_AUTOCONFIGURE: 'EMAIL_AUTOCONFIGURE',
  PASSWORD_CREATE_PATH: 'PASSWORD_CREATE_PATH',
  WP_STAGING_INCLUDE_FILES: 'WP_STAGING_INCLUDE_FILES',
  WP_STAGING_FULL_DEPLOY: 'WP_STAGING_FULL_DEPLOY',
  WP_STAGING_CUSTOM_DEPLOY: 'WP_STAGING_CUSTOM_DEPLOY',
  WP_STAGING_DESTROY: 'WP_STAGING_DESTROY',
  USER_CREATE_PROMPT: 'USER_CREATE_PROMPT',
  USER_CREATE_NEW_PROTECTED_URL: 'USER_CREATE_NEW_PROTECTED_URL',
  SSH_VIEW_PRIVATE_CERT: 'SSH_VIEW_PRIVATE_CERT',
  SSH_VIEW_PUBLIC_CERT: 'SSH_VIEW_PUBLIC_CERT',
  SSH_PRIVATE_KEY_NOT_AVAILABLE: 'SSH_PRIVATE_KEY_NOT_AVAILABLE',
  SSH_SHOW_CREDENTIAL: 'SSH_SHOW_CREDENTIAL',
  CLOUDFLARE_CNAME: 'CLOUDFLARE_CNAME',
  CLOUDFLARE_CANNOT_BE_ACTIVATED_DUE_CDN: 'CLOUDFLARE_CANNOT_BE_ACTIVATED_DUE_CDN',
  CDN_CANNOT_BE_ACTIVATED_CENTRAL_DNS: 'CDN_CANNOT_BE_ACTIVATED_CENTRAL_DNS',
  CDN_NOT_WORKING_CENTRAL_DNS: 'CDN_NOT_WORKING_CENTRAL_DNS',
  CDN_NOT_AVAILABLE_BETA: 'CDN_NOT_AVAILABLE_BETA',
  CDN_EMAIL_NOTIFY: 'CDN_EMAIL_NOTIFY',
  CDN_ACTIVATE_CF: 'CDN_ACTIVATE_CF',
  CDN_ACTIVATE_FREE_CF: 'CDN_ACTIVATE_FREE_CF',
  CDN_ACTIVATE_PAID_CF: 'CDN_ACTIVATE_PAID_CF',
  CDN_UPGRADE: 'CDN_UPGRADE',
  CLOUDFLARE_DEACTIVATE_ZONE: 'CLOUDFLARE_DEACTIVATE_ZONE',
  CLOUDFLARE_DEACTIVATE_PLUS_ZONE: 'CLOUDFLARE_DEACTIVATE_PLUS_ZONE',
  WP_AUTOUPDATE_LAST_BACKUP: 'WP_AUTOUPDATE_LAST_BACKUP',
  LIST_DB_ENTITIES: 'LIST_DB_ENTITIES',
  GIT_VIEW_REPO: 'GIT_VIEW_REPO',
  GIT_UPGRADE_PLAN: 'GIT_UPGRADE_PLAN',
  MX_RECORDS: 'MX_RECORDS',
  BUY_BACKUP: 'BUY_BACKUP',
  BACKUP_RESTORE_FULL_SITE_DIALOG: 'BACKUP_RESTORE_FULL_SITE_DIALOG',
  MYSQL_NO_USERS: 'MYSQL_NO_USERS',
  MYSQL_NO_DATABASES: 'MYSQL_NO_DATABASES',
  PGSQL_NO_USERS: 'PGSQL_NO_USERS',
  PGSQL_NO_DATABASES: 'PGSQL_NO_DATABASES',
  FEATURE_NOT_AVAILABLE: 'FEATURE_NOT_AVAILABLE',
  UPGRADE_SHARED_PLAN_DIALOG: 'UPGRADE_SHARED_PLAN_DIALOG',
  FTP_SETTING_DIALOG: 'FTP_SETTING_DIALOG',

  SG_SITESCANNER_CLEAN_UP: 'SG_SITESCANNER_CLEAN_UP',
  SG_SITESCANNER_MALWARE_DETAILS: 'SG_SITESCANNER_MALWARE_DETAILS',
  SG_SITESCANNER_QUOTA_REACHED: 'SG_SITESCANNER_QUOTA_REACHED',
  SG_SITESCNNER_UPGRADE_NOT_AVAILABLE: 'SG_SITESCNNER_UPGRADE_NOT_AVAILABLE',
  SG_SITESCANNER_QUARANTINE_DIALOG: 'SG_SITESCANNER_QUARANTINE_DIALOG',
  SG_SITESCANNER_LEGACY_USERS_FEATURE_NOT_AVAILABLE: 'SG_SITESCANNER_LEGACY_USERS_FEATURE_NOT_AVAILABLE',
  SG_SITESCANNER_BASIC_PLAN_USERS_FEATURE_NOT_AVAILABLE: 'SG_SITESCANNER_BASIC_PLAN_USERS_FEATURE_NOT_AVAILABLE',
  SG_SITESCANNER_QUARANTINE_CONFIRM_DIALOG: 'SG_SITESCANNER_QUARANTINE_CONFIRM_DIALOG',

  PHP_RESTORE_VARIABLE: 'PHP_RESTORE_VARIABLE',
  CHANGE_PHP_EXTENSION_SETTING: 'CHANGE_PHP_EXTENSION_SETTING',
  PHP_CHANGE_MOD: 'PHP_CHANGE_MOD',
  PHP_MOD_UPGRADE_DIALOG: 'PHP_MOD_UPGRADE_DIALOG',

  FILE_MANAGER_MESSAGE: 'FILE_MANAGER_MESSAGE',
  FILE_MANAGER_CONFIRM: 'FILE_MANAGER_CONFIRM',
  FILE_MANAGER_CONFIRM_FILE_UPLOAD: 'FILE_MANAGER_CONFIRM_FILE_UPLOAD',
  FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_COPY: 'FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_COPY',
  FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_MOVE: 'FILE_MANAGER_CONFIRM_FILES_OVERWRITE_FROM_MOVE',
  FILE_MANAGER_FAILED_UPLOAD: 'FILE_MANAGER_FAILED_UPLOAD',
  FILE_MANAGER_CONFIRM_CLOSE: 'FILE_MANAGER_CONFIRM_CLOSE',
  FILE_MANAGER_NO_PATH_TO_SELECT: 'FILE_MANAGER_NO_PATH_TO_SELECT',
  FILE_MANAGER_DELETE: 'FILE_MANAGER_DELETE',
  FILE_MANAGER_SELECT_DIRECTORY_TO_SEARCH: 'FILE_MANAGER_SELECT_DIRECTORY_TO_SEARCH',

  NEW_TAB_DIALOG: 'NEW_TAB_DIALOG',
  TOO_MANY_REQUEST_DIALOG: 'TOO_MANY_REQUEST_DIALOG',
  MESSAGE_DIALOG: 'MESSAGE_DIALOG',
  DEBUG_DIALOG: 'DEBUG_DIALOG',
  HORDE_EMAIL_DIALOG: 'HORDE_EMAIL_DIALOG',

  IMAGE_PREVIEW_DIALOG: 'IMAGE_PREVIEW_DIALOG',

  WP_MANAGE_DISABLE_PLUGINS_DIALOG: 'WP_MANAGE_DISABLE_PLUGINS_DIALOG'
};

export const PAGE_NOTIFICATION_SUBTITLE_PROPS: Partial<TitleProps> = {
  level: '6',
  density: 'cozy',
  color: 'dark',
  align: 'center'
};

export const enum LocalTaskLoaderType {
  CREATE_BOX = 'createBox'
}

export enum BuyBackupTypes {
  singleBackup = 'single_backup',
  growBig = 'shared_plus'
}

export const SESSION_EXPIRED_REASON = 'session-expired';

export const SG_OPTIMIZER_LINKS = {
  en: 'https://wordpress.org/plugins/sg-cachepress',
  it: 'https://it.wordpress.org/plugins/sg-cachepress',
  es: 'https://es.wordpress.org/plugins/sg-cachepress'
};
