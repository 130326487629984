import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Button,
  Flex,
  GetDeviceInformationType,
  Icon,
  Navigation,
  NavigationList,
  Spacer
} from '@siteground/styleguide';
import { getNavigationInfo, WmToolId } from '../../../../core/constants/route-info';
import { MenuItemST } from '../../../../core/definitions/menu-items';
import {
  MIN_WIDTH_WHEN_CONTENT_IS_NOT_TRANSLATED,
  SCREEN_WIDTH_FOR_MOBILE_DRAWER
} from '../../../../web/components/with-drawer';
import { logoutFromWm } from '../../../core/actions/logout';
import { saveNavigationCommand } from '../../../core/actions/page';
import { groups as WM_MENU_GROUPS, rcmailItems } from '../../../core/constants/menu-items';
import { WM_ROUTES } from '../../../core/constants/WM_ROUTES';
import NavigationListCompact from './navigation-list-compact';
import './wm-navigation.scss';

type StoreProps = {
  environment: GetDeviceInformationType;
  activePage: string;
  command: string;
};

type DispatchProps = {
  logoutFromWm: typeof logoutFromWm;
  saveNavigationCommand: typeof saveNavigationCommand;
} & WrappedComponentProps;

type OwnProps = {
  toggleDrawer: Function;
  location?: {
    search: string;
    pathname: string;
  };
  router?: { push: Function };
};

type Props = StoreProps & DispatchProps & OwnProps;

class WMNavigation extends React.PureComponent<Props> {
  componentDidMount(): void {
    const { location } = this.props;
    const locationPath = location.pathname.replace(/\/$/, '');
    const toolId = Object.keys(WM_ROUTES).find((key) => {
      const path = WM_ROUTES[key];
      return path.includes(locationPath);
    });

    if (toolId && toolId !== WmToolId.webmail) {
      this.props.saveNavigationCommand({
        command: null,
        task: toolId
      });
    }
  }

  onNavigationClick = (toolId) => {
    const { router, location, environment, toggleDrawer } = this.props;
    this.props.saveNavigationCommand({
      command: null,
      task: toolId
    });
    if (environment.width <= SCREEN_WIDTH_FOR_MOBILE_DRAWER) {
      toggleDrawer();
    }

    router.push(`${WM_ROUTES[toolId]}${location.search}`);
  };

  rcmailNavigationClick = ({ command, task }: { command?: string; task: string }) => {
    const { router } = this.props;
    this.props.saveNavigationCommand({
      command,
      task
    });
    router.push(`${WM_ROUTES[WmToolId.webmail]}`);
  };

  onLogOut = () => {
    this.props.logoutFromWm();
    this.props.saveNavigationCommand({
      command: '',
      task: 'logout'
    });
    this.onNavigationClick(WmToolId.wmLogin);
  };

  render() {
    const { intl, environment, activePage, command } = this.props;
    const menuItems = WM_MENU_GROUPS[0].items;

    const isMobileView = environment.width < MIN_WIDTH_WHEN_CONTENT_IS_NOT_TRANSLATED;

    if (isMobileView) {
      return (
        <NavigationListCompact
          onNavigationClick={this.onNavigationClick}
          menuItems={menuItems}
          onLogOut={this.onLogOut}
          activePage={activePage}
          command={command}
          rcmailNavigationClick={this.rcmailNavigationClick}
        />
      );
    }

    return (
      <Navigation>
        <Flex padding="medium">
          <Button
            size="x-large"
            color="primary"
            onClick={() =>
              this.rcmailNavigationClick({
                command: 'compose',
                task: 'mail'
              })
            }
          >
            <Icon name="edit-file" size="18" />
            <Spacer size="x-small" />
            {intl.formatMessage({ id: 'translate.wm.page.compose.button' })}
          </Button>
        </Flex>

        {rcmailItems.map((item) => {
          return (
            <NavigationList
              key={item.address}
              title={intl.formatMessage({ id: item.title })}
              icon={item.icon}
              onClick={() =>
                this.rcmailNavigationClick({
                  task: item.address
                })
              }
              active={activePage === item.address && command !== 'compose'}
            />
          );
        })}

        {menuItems.map((tool: MenuItemST) => {
          const toolInfo = getNavigationInfo(tool.toolId);
          const title = intl.formatMessage({ id: toolInfo.title });
          const icon = toolInfo.icon;

          return (
            <NavigationList
              key={tool.toolId}
              title={title}
              icon={icon}
              active={activePage === tool.toolId}
              onClick={() => this.onNavigationClick(tool.toolId)}
            />
          );
        })}

        <NavigationList
          icon="logout"
          title={intl.formatMessage({ id: 'translate.wm.navigation.logout' })}
          onClick={this.onLogOut}
        />
      </Navigation>
    );
  }
}

const mapStoreToProps = (store) => ({
  environment: store.environment,
  activePage: store.navigation.task,
  command: store.navigation.command
});

const mapDispatchToProps = {
  logoutFromWm,
  saveNavigationCommand
};

export default connect<StoreProps, DispatchProps, OwnProps>(
  mapStoreToProps,
  mapDispatchToProps
)(withRouter(injectIntl(WMNavigation)));
