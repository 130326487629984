import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import {
  cn,
  Context,
  DrawerIcon,
  Flex,
  GetDeviceInformationType,
  Header,
  Icon,
  Spacer,
  Text
} from '@siteground/styleguide';
import { getCurrentSiteToken } from '../../../../core/selectors';
import './wm-header.scss';

type StoreProps = {
  hasUserSession: any;
  emails: any[];
};

type OwnProps = {
  open: boolean;
  toggleDrawer: Function;

  device?: GetDeviceInformationType;
};

type Props = StoreProps & OwnProps;

class WMHeader extends React.PureComponent<Props> {
  render() {
    const { device, emails, open, toggleDrawer, hasUserSession } = this.props;
    const userEmailData = emails && emails[0];
    const email = userEmailData && `${userEmailData.name}@${userEmailData.domain_name}`;

    const classes = cn('wm-header', !hasUserSession && 'wm-header--login');

    if (!hasUserSession) {
      return (
        <Header className={classes}>
          <Icon name="logo-webmail" width="140" height="40" multicolor />
        </Header>
      );
    }

    return (
      <Header className={classes}>
        <Flex align="center" wrap="nowrap" style={{ width: '100%' }}>
          <Spacer size="x-small" />

          {!device.isPhone && (
            <React.Fragment>
              <Icon name="logo-webmail" width="140" height="40" multicolor />

              <Spacer />
            </React.Fragment>
          )}

          <Text color="white" size="large" weight="bold" truncate>
            {email}
          </Text>
        </Flex>
      </Header>
    );
  }
}

const SGHeaderWithDeviceInfo = (props) => (
  <Context.Consumer>{({ device }) => <WMHeader device={device} {...props} />}</Context.Consumer>
);

const mapStoreToProps = (store) => ({
  hasUserSession: getCurrentSiteToken(store),
  emails: store.pageItems.email
});

export default connect<StoreProps, any, OwnProps>(mapStoreToProps)(injectIntl(SGHeaderWithDeviceInfo));
