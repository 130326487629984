import * as React from 'react';
import { useIntl } from 'react-intl';
import { API_RESOURCE } from '../../../../core/constants/api';
import CreateBox from '../../../../web/components/create-box';
import { WM_REDUX_FORM } from '../../../core/constants/common';

type Props = {
  children: any;
  renderBeforeBoxChildren?: any;
};

const { resourceName, resourceNameMetaApi } = API_RESOURCE.EMAIL;
const formName = WM_REDUX_FORM.CHANGE_EMAIL_PASSWORD;

const CreateContainer = ({ children, renderBeforeBoxChildren }: Props) => {
  const intl = useIntl();
  return (
    <CreateBox
      hideTutorials
      title={intl.formatMessage({ id: 'translate.wm.login.credentials.title' })}
      resourceName={resourceName}
      formName={formName}
      renderBeforeBoxChildren={renderBeforeBoxChildren}
      resources={[
        { resourceName: API_RESOURCE.EMAIL.resourceName, methods: ['GET'] },
        { resourceName: API_RESOURCE.EMAIL.resourceName, methods: ['PUT'] }
      ]}
    >
      {children}
    </CreateBox>
  );
};

export default CreateContainer;
