import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import store from './store';
import Root from './components/root';

const history = syncHistoryWithStore(browserHistory, store);

browserHistory.listen((location) => {
  if (!(window as any).dataLayer) {
    return;
  }

  (window as any).dataLayer.push({
    event: 'pageview',
    page: {
      path: location.pathname,
      title: location.pathname.replace('/', '')
    }
  });
});

ReactDOM.render(<Root store={store} history={history} />, document.getElementById('root'));
