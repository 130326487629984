import * as React from 'react';
import { connect } from 'react-redux';
import { ToolWrapper } from '../../web/contexts';

type StoreProps = {};

type DispatchProps = {};

type OwnProps = {
  Component: React.ComponentClass;
  toolId: string;
  pageId?: string;
  props?: any;
};

type Props = StoreProps & DispatchProps & OwnProps;

const RouteWrapper: React.FunctionComponent<Props> = ({ Component, toolId, pageId, props }) => (
  <ToolWrapper.Provider value={{ toolId, pageId }}>
    <Component {...props} />
  </ToolWrapper.Provider>
);

const mapStoreToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect<StoreProps, DispatchProps, OwnProps>(mapStoreToProps, mapDispatchToProps)(RouteWrapper);
