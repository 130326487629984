import * as React from 'react';
import { DIALOGS } from '../../../../core/constants/common';
import { SGDialog, SGDialogCancel } from '../../../components/sg-dialog';
import DebugDialogContent from './debug-dialog-content';

const DebugDialog = () => (
  <SGDialog
    id={DIALOGS.DEBUG_DIALOG}
    icon="product-tools"
    iconSize="60"
    iconColor="primary"
    size="large"
    footer={<SGDialogCancel id={DIALOGS.DEBUG_DIALOG} label="Close" />}
  >
    <DebugDialogContent />
  </SGDialog>
);

export default DebugDialog;
