import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import { WmToolId } from '../core/constants/route-info';
import InlineErrorPage from '../web/pages/inline-error-page';
import RouteWrapper from './components/route-wrapper';

import { WEBMAIL_PATH_PREFIX, WM_ROUTES } from './core/constants/WM_ROUTES';
import { WMApp } from './pages/app';
import { WMEmailFilters } from './pages/email-filters';
import { WMLogin } from './pages/log-in';
import { WMLoginCredentials } from './pages/login-credentials';
import { WMMailConfiguration } from './pages/mail-configuration';
import { WMMailAutoresponder } from './pages/mail-autoresponder';
import { WMIframe } from './pages/webmail-iframe';
import { WMMailForwarders } from './pages/mail-forwarders';

const PageNotFound = () => <InlineErrorPage type="not-found" />;

const routeWrapper =
  ({ toolId, pageId = null, Component }) =>
  (props) =>
    <RouteWrapper toolId={toolId} pageId={pageId} Component={Component} props={props} />;

const WMRouter = ({ history }) => {
  return (
    <Router history={history}>
      <Route path="/" component={WMApp}>
        <IndexRoute component={WMIframe} />

        {[`/${WEBMAIL_PATH_PREFIX}`, `/${WEBMAIL_PATH_PREFIX}/`, WM_ROUTES[WmToolId.webmail]].map((path, index) => (
          <Route path={path} component={WMIframe} key={index} />
        ))}

        <Route
          path={WM_ROUTES[WmToolId.wmLogin]}
          component={routeWrapper({ toolId: WmToolId.wmLogin, Component: WMLogin })}
        />

        <Route
          path={WM_ROUTES[WmToolId.wmLoginCredentials]}
          component={routeWrapper({ toolId: WmToolId.wmLoginCredentials, Component: WMLoginCredentials })}
        />

        <Route
          path={WM_ROUTES[WmToolId.wmEmailConfig]}
          component={routeWrapper({ toolId: WmToolId.wmEmailConfig, Component: WMMailConfiguration })}
        />

        <Route
          path={WM_ROUTES[WmToolId.wmEmailAutoresponder]}
          component={routeWrapper({
            toolId: WmToolId.wmEmailAutoresponder,
            Component: WMMailAutoresponder
          })}
        />

        <Route
          path={WM_ROUTES[WmToolId.wmEmailFilters]}
          component={routeWrapper({ toolId: WmToolId.wmEmailFilters, Component: WMEmailFilters })}
        />

        <Route
          path={WM_ROUTES[WmToolId.wmEmailForward]}
          component={routeWrapper({ toolId: WmToolId.wmEmailForward, Component: WMMailForwarders })}
        />

        <Route path="*" component={PageNotFound} />
      </Route>
    </Router>
  );
};

export default WMRouter;
