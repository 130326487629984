import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Button, Grid, Input, Section, Text, Title } from '@siteground/styleguide';
import { LoginCard } from '@siteground/styleguide/lib/composite';
import customRequestTypes from '../../../core/constants/custom-request-types';
import { WmToolId } from '../../../core/constants/route-info';
import PartialLoader from '../../../web/components/partial-loader/partial-loader';
import { login } from '../../core/actions/login';
import { createNotification } from '../../../core/actions/notifications';
import { WEBMAIL_PATH_PREFIX, WM_ROUTES } from '../../core/constants/WM_ROUTES';

import './wm-log-in.scss';

type StoreProps = {};

type DispatchProps = {
  login: typeof login;
  createNotification: typeof createNotification;
} & WrappedComponentProps;

type OwnProps = {
  intl: Intl;
};

type Props = StoreProps & DispatchProps & OwnProps;

const WmLogIn = (props: Props) => {
  const { intl, login, createNotification } = props;
  let email = null;
  let password = null;

  const [loginInfo, setLoginInfo] = React.useState({
    serverHostname: CONFIG.WEBMAIL_TEST_STAGE
  });

  const [authError, setAuthError] = React.useState(null);

  React.useEffect(() => {
    fetch(`${location.origin}/${WEBMAIL_PATH_PREFIX}`, { method: 'GET', headers: {} })
      .then((response) => {
        for (const entry of (response.headers as any).entries()) {
          if (entry[0] === 'x-server-name' && entry[1]) {
            setLoginInfo({
              ...loginInfo,
              serverHostname: entry[1]
            });
          }
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const onSubmit = () => {
    const { serverHostname } = loginInfo;

    setAuthError(null);

    login({
      email: email.value,
      password: password.value,
      serverHostname,
      onComplete: () => browserHistory.push(WM_ROUTES[WmToolId.webmail]),
      onFailure: ({ status, message }) => {
        if (message && status === 401) {
          return setAuthError(message);
        }

        createNotification({
          type: 'generic',
          state: 'error',
          error: {
            intlKey: 'translate.wm-login.login-failed.message'
          }
        });
      }
    });
  };

  return (
    <Section>
      <LoginCard data-e2e="log-in">
        <PartialLoader
          resources={[
            {
              requestTypeName: customRequestTypes.WEBMAIL_LOGIN
            }
          ]}
        >
          <Grid gap="responsive">
            <Grid gap="small">
              <div>
                <Title level="1" weight="light" align="center" density="cozy">
                  {intl.formatMessage({ id: 'translate.wm.log.in.title' })}
                </Title>

                <Text weight="bold" color="dark" align="center">
                  {intl.formatMessage({ id: 'translate.wm.log.in.subtitle' })}
                </Text>
              </div>
            </Grid>

            <form action="" onSubmit={(e) => e.preventDefault()}>
              <Grid gap="responsive">
                <Input
                  autoFocus
                  showValidation
                  type="text"
                  size="x-large"
                  name="email"
                  data-e2e="input-email"
                  onRefsReady={(input) => (email = input)}
                  state={authError ? 'error' : 'none'}
                  validationMessage={authError}
                  label={intl.formatMessage({ id: 'translate.wm.log.in.email.label' })}
                  onChange={() => {
                    setAuthError(null);
                  }}
                />

                <Input
                  showValidation
                  name="password"
                  type="password"
                  size="x-large"
                  data-e2e="input-password"
                  onRefsReady={(input) => (password = input)}
                  state={authError ? 'error' : 'none'}
                  label={intl.formatMessage({ id: 'translate.wm.log.in.password.label' })}
                  onChange={() => {
                    setAuthError(null);
                  }}
                />

                <Button color="secondary" size="x-large" onClick={onSubmit}>
                  {intl.formatMessage({ id: 'translate.wm.log.in.submit' })}
                </Button>
              </Grid>
            </form>
          </Grid>
        </PartialLoader>
      </LoginCard>
    </Section>
  );
};

const mapStoreToProps = (store) => ({});

export default connect<StoreProps, DispatchProps, OwnProps>(mapStoreToProps, { login, createNotification })(
  injectIntl(WmLogIn)
);
