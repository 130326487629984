import * as React from 'react';

import { updateItem } from '../../../core/actions/crud';
import { httpRequestStarted, httpRequestSucceeded } from '../../../core/actions/fetch';
import { requestData } from '../../../core/actions/request-data';
import { API_RESOURCE } from '../../../core/constants/api';
import customRequestTypes from '../../../core/constants/custom-request-types';
import indexWithCRUD from '../../../web/components/indexWithCRUD';
import PartialLoader from '../../../web/components/partial-loader/partial-loader';
import { WebMail } from '../../../web/pages/email';
import { login } from '../../core/actions/login';
import { getMainEmail } from '../../core/selectors/email';
import { getRcmailIframe } from '../../core/utils/common';

import './webmail-iframe.scss';

type StoreProps = {
  email: any;
  navigation: {
    task: string;
    command: string;
  };
};

type DispatchProps = {
  login: typeof login;
  updateItem: typeof updateItem;
  requestData: typeof requestData;
  httpRequestStarted: typeof httpRequestStarted;
  httpRequestSucceeded: typeof httpRequestSucceeded;
};

type OwnProps = {
  intl: Intl;
};

type Props = StoreProps & DispatchProps & OwnProps;

type State = {
  baseIframeURL: string;
};

class WebmailIframe extends React.Component<Props, State> {
  readonly state = {
    baseIframeURL: null
  };

  componentDidMount() {
    this.getLoginLinkForRoundCube();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      this.getLoginLinkForRoundCube();
    }
  }

  getLoginLinkForRoundCube = async () => {
    const { email, httpRequestStarted } = this.props;

    if (!email) {
      console.warn('No email data');
      return;
    }

    const { extra } = email;
    let webmail = extra && extra.webmail;

    if (!webmail) {
      webmail = WebMail.RoundCube;
    }

    const urlParams: any = {
      email_id: email.id
    };

    if (CONFIG.WEBMAIL_USE_SAME_ORIGIN) {
      urlParams.url = location.origin;
    }

    this.props.requestData(
      {
        payload: {
          _metaFields: {
            endpoint: `${API_RESOURCE.EXTAPP.endpoint}/${webmail}`,
            method: 'GET'
          },
          urlParams
        },
        requestTypeName: customRequestTypes.GET_WEBMAIL_LOGIN_DATA
      },
      ({ app_login }) => {
        httpRequestStarted({
          requestTypeName: customRequestTypes.WEBMAIL_LOAD_IFRAME
        });
        this.setState({ baseIframeURL: app_login });
      }
    );
  };

  getIframeURL = () => {
    const { baseIframeURL } = this.state;
    const { task, command } = this.props.navigation;
    const iframe: any = getRcmailIframe();

    const rcmail = iframe && iframe.rcmail;

    if (!baseIframeURL) {
      return null;
    }

    if (baseIframeURL && !rcmail) {
      return baseIframeURL;
    }

    const token = rcmail.env.request_token;
    const location = iframe.location.href;
    const iframeURL = new URL(location);

    iframeURL.searchParams.set('_token', token);

    if (task) {
      iframeURL.searchParams.set('_task', task);
    }

    if (command) {
      iframeURL.searchParams.set('_action', command);
    } else {
      iframeURL.searchParams.delete('_action');
    }
    const date = new Date().toString();
    iframeURL.searchParams.set('_timestamp', date);

    return iframeURL.href;
  };

  setCSSStyles = () => {
    const iframe: any = document.getElementById('sg-webmail');

    if (!iframe) {
      return;
    }

    const style = document.createElement('STYLE');
    style.innerHTML = `
       .button-logout,
        .about-link,
        .username,
        .special-buttons,

        #topline {
          display: none !important;
        }
    `;

    if (iframe.contentWindow && iframe.contentWindow.document) {
      iframe.contentWindow.document.head.appendChild(style);
    }
  };

  renderIframe = () => {
    const { httpRequestSucceeded } = this.props;
    const iframeURL = this.getIframeURL();

    if (!iframeURL) {
      return null;
    }

    return (
      <iframe
        id="sg-webmail"
        className="sg-webmail"
        src={iframeURL}
        onLoad={(event) => {
          this.props.httpRequestStarted({
            requestTypeName: customRequestTypes.WEBMAIL_LOAD_IFRAME
          });

          this.setCSSStyles();

          httpRequestSucceeded({
            requestTypeName: customRequestTypes.WEBMAIL_LOAD_IFRAME
          });
        }}
      />
    );
  };

  render() {
    return (
      <PartialLoader
        resources={[
          { resourceName: API_RESOURCE.EMAIL.resourceName, methods: ['GET'] },
          { resourceName: API_RESOURCE.EXTAPP.resourceName, methods: ['GET'] },
          { requestTypeName: customRequestTypes.GET_WEBMAIL_LOGIN_DATA },
          { requestTypeName: customRequestTypes.WEBMAIL_LOAD_IFRAME }
        ]}
      >
        {this.renderIframe()}
      </PartialLoader>
    );
  }
}

const mapStoreToProps = (store) => ({
  email: getMainEmail(store),
  navigation: store.navigation
});

export default indexWithCRUD(mapStoreToProps, {
  updateItem,
  requestData,
  httpRequestStarted,
  httpRequestSucceeded
})(WebmailIframe, API_RESOURCE.EMAIL);
