import * as React from 'react';
import { IntlShape } from 'react-intl';
import { Colors, Grid, Label, Section } from '@siteground/styleguide';
import { closeSGDialog, openSGDialog } from '../../../core/actions/sg-dialog';
import { API_RESOURCE } from '../../../core/constants/api';
import { DIALOGS } from '../../../core/constants/common';
import { WmToolId } from '../../../core/constants/route-info';
import { findMainDomain } from '../../../core/selectors';
import DateWithTime from '../../../web/components/date-with-time';
import { DeleteDialog } from '../../../web/components/dialogs';
import indexWithCRUD from '../../../web/components/indexWithCRUD';
import PageHeader from '../../../web/components/page-header/page-header';
import { SGDialogForm } from '../../../web/components/sg-dialog';
import SGTable from '../../../web/components/sg-table';
import TableContextMenu from '../../../web/components/table-context-menu/table-context-menu';
import { WM_REDUX_FORM } from '../../core/constants/common';
import { getMainEmail } from '../../core/selectors/email';
import { formatEmail } from '../../core/utils/format';
import { CreateBox, CreateForm } from './create';

import './mail-autoresponder.scss';
import UpdateFieldsForm from './update-fields-form';

type StoreProps = {
  email: {
    name: string;
  };
  mainDomain: {
    id: string;
  };
  items: {
    emailAutoresponder: any[];
  };
};

type DispatchProps = {
  actions: {
    createItem: CreateItem;
    updateItem: UpdateItem;
    deleteItem: DeleteItem;
  };
  openSGDialog: typeof openSGDialog;
  closeSGDialog: typeof closeSGDialog;
};

type OwnProps = {
  intl: Intl;
};

type Props = StoreProps & DispatchProps & OwnProps;

type State = {
  updatePayload: any;
  deletePayload: any;
};

export enum AutoresponderStatus {
  Inactive = 'inactive',
  Active = 'active',
  Scheduled = 'scheduled'
}

type LabelTypesByStatus = {
  [key: string]: Colors;
};

const LabelTypesByStatus: LabelTypesByStatus = {
  [AutoresponderStatus.Inactive]: 'error',
  [AutoresponderStatus.Active]: 'success',
  [AutoresponderStatus.Scheduled]: undefined
};

const LabelTextByStatus = {
  [AutoresponderStatus.Inactive]: 'translate.generic.inactive',
  [AutoresponderStatus.Active]: 'translate.generic.active',
  [AutoresponderStatus.Scheduled]: 'translate.generic.scheduled'
};

export const getAutoresponderStatus = (start: number, end: number): AutoresponderStatus => {
  const now = +new Date() / 1000;
  const unlimitedEndDate = Boolean(end === 0);

  switch (true) {
    case start < now && (now < end || unlimitedEndDate):
      return AutoresponderStatus.Active;
    case end < now:
      return AutoresponderStatus.Inactive;
    case now < start:
      return AutoresponderStatus.Scheduled;
    default:
  }
};

const INITIAL_WAIT_TIME = 24 * 60 * 60; // 24h in seconds

export const MailAutoresponder = (props: Props) => {
  const { intl, items, email, actions, mainDomain, openSGDialog, closeSGDialog } = props;
  const domainId = mainDomain && mainDomain.id;
  const emailName = email && email.name;
  const formattedEmail = formatEmail(email);

  const [updatePayload, setUpdatePayload] = React.useState(null);
  const [deletePayload, setDeletePayload] = React.useState(null);

  const onCreateFormSubmit = React.useCallback(
    (formData) => {
      actions.createItem({
        ...formData,
        domain_id: domainId,
        name: emailName,
        _meta: {
          notification: {
            type: 'form',
            formName: WM_REDUX_FORM.CREATE_AUTO_RESPONDER_WEB_MAIL,
            success: {
              intlKey: 'translate.wm.create-autoresponder.created_msg',
              intlValues: { name: formattedEmail }
            },
            error: {
              intlKey: 'translate.wm.create-autoresponder.failed_create_msg',
              intlValues: { name: formattedEmail }
            }
          }
        }
      });
    },
    [domainId, emailName]
  );

  const renderAutoResponderStatus = React.useCallback((entity, row) => {
    const status = getAutoresponderStatus(row.ts_start, row.ts_stop);

    return (
      <Label
        color={LabelTypesByStatus[status]}
        type="link"
        padding={['inherit', 'inherit', 'inherit', 'none']}
        size="medium"
      >
        {intl.formatMessage({ id: LabelTextByStatus[status] })}
      </Label>
    );
  }, []);

  const renderTableContextMenu = React.useCallback((id, entity) => {
    const entityName = formatEmail(entity);

    const nextDeletePayload: DeleteItemPayload = {
      itemId: id,
      entityName,
      _metaFields: { ...API_RESOURCE.EMAIL_AUTORESPONDER },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.wm-autoresponder.deleted_msg',
            intlValues: { email: entityName }
          },
          error: {
            intlKey: 'translate.wm-autoresponder.failed_delete_msg',
            intlValues: { email: entityName }
          }
        }
      }
    };

    const nextUpdatePayload = {
      entityName,
      _metaFields: {
        ...API_RESOURCE.EMAIL_AUTORESPONDER
      },
      _meta: {
        notification: {
          type: 'generic',
          success: {
            intlKey: 'translate.wm-autoresponder.updated_msg',
            intlValues: { email: entityName }
          },
          error: {
            intlKey: 'translate.wm-autoresponder.failed_update_msg',
            intlValues: { email: entityName }
          }
        }
      },
      ...entity
    };
    return (
      <TableContextMenu
        entity={entity}
        resourceName={API_RESOURCE.EMAIL_AUTORESPONDER.resourceNameMetaApi}
        items={[
          {
            vcsMethod: 'PUT',
            label: intl.formatMessage({ id: 'translate.generic.edit' }),
            e2eAttr: 'table-action-edit',
            icon: 'edit',
            visibleOnDesktop: true,
            onClick: () => {
              setUpdatePayload(nextUpdatePayload);
              openSGDialog(WM_REDUX_FORM.CHANGE_EMAIL_AUTORESPONDER_DIALOG_WEB_MAIL);
            }
          },
          {
            vcsMethod: 'DELETE',
            label: intl.formatMessage({ id: 'translate.generic.delete' }),
            e2eAttr: 'table-action-delete',
            icon: 'trash',
            visibleOnDesktop: true,
            onClick: () => {
              setDeletePayload(nextDeletePayload);
              openSGDialog(DIALOGS.GENERIC_DELETE);
            }
          }
        ]}
      />
    );
  }, []);

  const renderDeleteConformationDialogComponent = () => {
    const entityName = deletePayload && deletePayload.entityName;
    return (
      <DeleteDialog
        title={intl.formatMessage({ id: 'translate.wm-autoresponder.delete.dialog.title' }, { entityName })}
        onSubmit={() => actions.deleteItem(deletePayload)}
      />
    );
  };

  const renderUpdateComponent = () => {
    const entityName = updatePayload && updatePayload.entityName;

    return (
      <SGDialogForm
        name={WM_REDUX_FORM.CHANGE_EMAIL_AUTORESPONDER_DIALOG_WEB_MAIL}
        title={intl.formatMessage({ id: 'translate.wm-autoresponder.update.title' }, { entityName })}
        size="x-large"
        resources={[
          {
            resourceName: API_RESOURCE.EMAIL_AUTORESPONDER.resourceName,
            methods: ['PUT']
          }
        ]}
      >
        <UpdateFieldsForm
          email={formatEmail(updatePayload)}
          initialValues={updatePayload}
          selectedDomain={mainDomain}
          onSubmit={(data) =>
            actions.updateItem(data, () => closeSGDialog(WM_REDUX_FORM.CHANGE_EMAIL_AUTORESPONDER_DIALOG_WEB_MAIL))
          }
        />
      </SGDialogForm>
    );
  };

  return (
    <React.Fragment>
      <PageHeader
        id={WmToolId.wmEmailAutoresponder}
        instructions={intl.formatMessage({ id: 'translate.wm.autoresponder.info' })}
      />
      <Section>
        <Grid>
          <CreateBox>
            <CreateForm
              email={formatEmail(email)}
              initialValues={{
                wait_time: INITIAL_WAIT_TIME,
                _metaFields: {
                  ...API_RESOURCE.EMAIL_AUTORESPONDER
                }
              }}
              onSubmit={(data) =>
                onCreateFormSubmit({
                  ...data,
                  ts_start: data.ts_start || Math.floor(new Date().getTime() / 1000)
                })
              }
            />
          </CreateBox>
          <SGTable
            title={intl.formatMessage({ id: 'translate.wm.autoresponder.table.title' })}
            data={items.emailAutoresponder}
            noDataMessage="translate.wm-autoresponder.sg-table.no-data.message"
            resources={[{ resourceName: API_RESOURCE.EMAIL_AUTORESPONDER.resourceName, methods: ['GET'] }]}
            columns={[
              {
                header: intl.formatMessage({ id: 'translate.generic.email' }),
                accessor: 'name',
                render: (name, entity) => `${name}@${entity.domain_name}`
              },
              {
                header: intl.formatMessage({ id: 'translate.wm-autoresponder.subject' }),
                accessor: 'email_subject'
              },
              {
                header: intl.formatMessage({ id: 'translate.generic.status' }),
                render: renderAutoResponderStatus,
                accessor: 'active'
              },
              {
                header: intl.formatMessage({ id: 'translate.wm-autoresponder.start.date' }),
                render: (entity: any) => {
                  if (Number.isNaN(parseInt(entity, 10)) || entity === 0) {
                    return '';
                  }

                  return <DateWithTime date={entity} />;
                },
                accessor: 'ts_start'
              },
              {
                header: intl.formatMessage({ id: 'translate.wm-autoresponder.end.date' }),
                render: (entity: any) => {
                  if (Number.isNaN(parseInt(entity, 10)) || entity === 0) {
                    return intl.formatMessage({ id: 'translate.wm-autoresponder.always_active' });
                  }

                  return <DateWithTime date={entity} />;
                },
                accessor: 'ts_stop'
              },
              {
                header: intl.formatMessage({ id: 'translate.generic.actions' }),
                accessor: 'id',
                render: renderTableContextMenu,
                align: 'right'
              }
            ]}
          />
        </Grid>
        {renderDeleteConformationDialogComponent()}
        {renderUpdateComponent()}
      </Section>
    </React.Fragment>
  );
};

const mapStoreToProps = (store) => ({
  mainDomain: findMainDomain(store, API_RESOURCE.DOMAIN_ALL.resourceName),
  email: getMainEmail(store)
});

export default indexWithCRUD(mapStoreToProps, { openSGDialog, closeSGDialog })(
  MailAutoresponder,
  API_RESOURCE.EMAIL_AUTORESPONDER,
  API_RESOURCE.EMAIL,
  API_RESOURCE.DOMAIN_ALL
);
