import customRequestTypes from '../../../core/constants/custom-request-types';

export const enum ACTIONS {
  pageLoad = 'webmail/PAGE_LOAD',
  saveNavigationCommand = 'webmail/SAVE_NAVIGATION_COMMAND'
}

export type PageLoadAction = {
  type: ACTIONS.pageLoad;
};

export const pageLoad = () => ({
  type: ACTIONS.pageLoad,
  requestTypeName: customRequestTypes.WEBMAIL_LOGIN
});

export const saveNavigationCommand = ({ command, task }: { command?: string; task: string }) => ({
  type: ACTIONS.saveNavigationCommand,
  payload: {
    task,
    command
  }
});
