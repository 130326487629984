import * as React from 'react';
import { cn, Flex, Icon } from '@siteground/styleguide';

type Props = {
  icon: string;
  onClick: any;
  isActive?: boolean;
  tooltip: string;
};

const NavigationItemCompact = ({ icon, onClick, isActive, tooltip }: Props) => (
  <div className="wm-navigation-list">
    <Flex
      className={cn('wm-navigation-list__item', isActive && 'wm-navigation-list__item--active')}
      justify="center"
      align="center"
      onClick={onClick}
    >
      <Icon className="wm-navigation-list__icon" name={icon} size="18" tooltip={tooltip} />
    </Flex>
  </div>
);

export default NavigationItemCompact;
