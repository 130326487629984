import * as React from 'react';
import { IntlShape } from 'react-intl';
import { Grid, Section, Tab, Tabs } from '@siteground/styleguide';

import { openNewTabAction } from '../../../core/actions/open-new-tab';
import { requestAutoconfigureData } from '../../../core/actions/pages/email-accounts';
import { API_RESOURCE } from '../../../core/constants/api';
import { WmToolId } from '../../../core/constants/route-info';
import { findMainDomain } from '../../../core/selectors';
import indexWithCRUD from '../../../web/components/indexWithCRUD';
import ListBox from '../../../web/components/list-box';
import PageHeader from '../../../web/components/page-header/page-header';
import { WebMail } from '../../../web/pages/email';
import { WM_REDUX_FORM } from '../../core/constants/common';
import { getMainEmail } from '../../core/selectors/email';
import { CreateBox, CreateForm } from './create/';

type StoreProps = {};

type DispatchProps = {
  requestAutoconfigureData: typeof requestAutoconfigureData;
  openNewTabAction: typeof openNewTabAction;
};

type OwnProps = {
  intl: Intl;
  actions: {
    updateItem: UpdateItem;
  };
  mainDomain: any;
  email: {
    id: number;
    name: string;
    domain_name: string;
    extra: {
      webmail: WebMail;
    };
  };
};

type Props = StoreProps & DispatchProps & OwnProps;

enum EmailConfigurationType {
  AutoConfigure = 'auto-configure',
  ManualConfigure = 'manual-configure'
}

type State = {
  selectedConfigurationType: EmailConfigurationType.AutoConfigure | EmailConfigurationType.ManualConfigure;
};

class MailConfiguration extends React.PureComponent<Props, State> {
  readonly state = {
    selectedConfigurationType: EmailConfigurationType.AutoConfigure
  };

  setCreateBoxTab = (type: EmailConfigurationType) => {
    this.setState({ selectedConfigurationType: type });
  };

  getDomainConfig = () => {
    const { mainDomain } = this.props;
    const config = mainDomain && mainDomain.config;

    return {
      imap_host: null,
      smtp_host: null,
      imap_port: 993,
      pop3_port: 995,
      smtp_port: 465,
      ...config
    };
  };

  renderCreateBoxTabs: any = () => {
    const { intl } = this.props;
    const { selectedConfigurationType } = this.state;

    return (
      <Tabs border="light">
        <Tab
          data-e2e="autoconfig-tab"
          active={selectedConfigurationType === EmailConfigurationType.AutoConfigure}
          onClick={() => this.setCreateBoxTab(EmailConfigurationType.AutoConfigure)}
        >
          {intl.formatMessage({ id: 'translate.wm.mail.config.auto.config.tab' })}
        </Tab>
        <Tab
          data-e2e="manual-tab"
          active={selectedConfigurationType === EmailConfigurationType.ManualConfigure}
          onClick={() => this.setCreateBoxTab(EmailConfigurationType.ManualConfigure)}
        >
          {intl.formatMessage({ id: 'translate.wm.mail.config.manual.config.tab' })}
        </Tab>
      </Tabs>
    );
  };

  renderAutoConfigureSetting = () => {
    const { email } = this.props;

    return (
      <CreateForm
        onSubmit={(data) => {
          this.props.requestAutoconfigureData({
            emailId: email.id,
            ...data,
            onDataLoaded: (url) => this.props.openNewTabAction({ url }),
            _meta: {
              notification: {
                type: 'form',
                formName: WM_REDUX_FORM.CREATE_AUTO_CONFIGURE_WEB_MAIL,
                success: {
                  intlKey: 'translate.wm.mail.config.create.autoconfig.success.message'
                },
                error: {
                  intlKey: 'translate.wm.mail.config.create.autoconfig.error.message'
                }
              }
            }
          });
        }}
      />
    );
  };

  renderManualConfigureSettings = () => {
    const { intl, email } = this.props;
    const domainConfig = this.getDomainConfig();
    const userEmail = email && `${email.name}@${email.domain_name}`;

    return (
      <Grid padding="responsive">
        <ListBox
          label={intl.formatMessage({ id: 'translate.wm.mail.config.manual.settings.label' })}
          data={[
            {
              label: intl.formatMessage({ id: 'translate.wm.mail.config.manual.settings.username.label' }),
              value: userEmail
            },
            {
              label: intl.formatMessage({ id: 'translate.wm.mail.config.manual.settings.password.label' }),
              value: intl.formatMessage({ id: 'translate.wm.mail.config.use.user.password' })
            },
            {
              label: intl.formatMessage({ id: 'translate.wm.mail.config.manual.settings.incoming.server.label' }),
              value: domainConfig.imap_host
            },
            {
              label: intl.formatMessage({ id: 'translate.wm.mail.config.manual.settings.imap.port.label' }),
              value: domainConfig.imap_port
            },
            {
              label: intl.formatMessage({ id: 'translate.wm.mail.config.manual.settings.pop3.port.label' }),
              value: domainConfig.pop3_port
            },
            {
              label: intl.formatMessage({ id: 'translate.wm.mail.config.manual.settings.outgoing.server.label' }),
              value: domainConfig.smtp_host
            },
            {
              label: intl.formatMessage({ id: 'translate.wm.mail.config.manual.settings.smtp.port.label' }),
              value: domainConfig.smtp_port
            }
          ]}
        />
      </Grid>
    );
  };

  render() {
    const { intl } = this.props;
    const { selectedConfigurationType } = this.state;

    return (
      <React.Fragment>
        <PageHeader
          id={WmToolId.wmEmailConfig}
          instructions={intl.formatMessage({ id: 'translate.wm.mail.config.info' })}
        />

        <Section>
          <Grid>
            <CreateBox renderBeforeBoxChildren={this.renderCreateBoxTabs()}>
              {selectedConfigurationType === EmailConfigurationType.AutoConfigure && this.renderAutoConfigureSetting()}

              {selectedConfigurationType === EmailConfigurationType.ManualConfigure &&
                this.renderManualConfigureSettings()}
            </CreateBox>
          </Grid>
        </Section>
      </React.Fragment>
    );
  }
}

const mapStoreToProps = (store) => ({
  mainDomain: findMainDomain(store, API_RESOURCE.DOMAIN_ALL.resourceName),
  email: getMainEmail(store)
});

export default indexWithCRUD(mapStoreToProps, { requestAutoconfigureData, openNewTabAction })(
  MailConfiguration,
  API_RESOURCE.EMAIL,
  API_RESOURCE.DOMAIN_ALL
);
